import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../../services/dashboard.service";
import { formatDate } from "src/app/utils/formatDate";
import { Router } from "@angular/router";

@Component({
  selector: "dashboard-component",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  dashboardData: any = [];
  isError: boolean = false;
  listTopServiceCallsWithoutAccess: any;
  topServiceIsNotWorkingInspections: any;
  errorMessage: string;
  currentRole: string;
  constructor(
    private dashboardService: DashboardService,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "admin" || this.currentRole === "manager") {
      this.setData();
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["inspector/inspections"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }

  setData() {
    this.dashboardService.getData().subscribe(
      (response: any) => {
        this.dashboardData = response.data;
        this.listTopServiceCallsWithoutAccess = response.data.topServiceCallsWithoutAccess.map(
          (element) => {
            return {
              ...element,
              completionDate: formatDate(element.completionDate),
            };
          }
        );

        this.topServiceIsNotWorkingInspections = response.data.topServiceIsNotWorkingInspections.map(
          (element) => {
            return {
              ...element,
              completionDate: formatDate(element.completionDate),
            };
          }
        );
      },
      (error) => {
        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = error.statusText;
        }
      }
    );
  }

  showView(inspectionId) {
    this.router.navigate(["/administration/inspection/" + inspectionId]);
  }
}
