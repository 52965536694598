import { Component, OnInit } from "@angular/core";
import { TMService } from "../../services/tm.service";
import { ActivatedRoute, Router } from "@angular/router";
import { InspectionsService } from "../../services/inspections.service";
import { formatDate } from "../../utils/formatDate";
@Component({
  selector: "detail-tm",
  templateUrl: "./detail-tm.component.html",
  styleUrls: ["./detail-tm.component.css"],
})
export class DetailTMComponent implements OnInit {
  detailTM: any = {};
  TMdevice: any;
  tmId: string = "";
  isError: boolean;
  inspectorName: string = "";
  buildingAddress: string = "";
  errorMessage: string;
  inspectionId: string;
  currentReports: [];
  currentRole: string;

  constructor(
    private TMService: TMService,
    private route: ActivatedRoute,
    private router: Router,
    private inspectionsService: InspectionsService
  ) { }

  ngOnInit() {
    this.tmId = this.route.snapshot.params.TMid;

    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "manager" || this.currentRole === "admin") {
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
    this.TMService.getTM(this.tmId).subscribe(
      (response: any) => {
        this.detailTM.status = response.data.status;
        this.currentReports = response.data.reports;
        this.setHeader(response.data.inspection.id);

        this.detailTM = {
          ...this.detailTM,
          completionDate: formatDate(response.data.completionDate),
        };

        this.TMdevice = response.data.devicesResult.map((element) => {
          return {
            ...element,
            isDone: element.isDone == true ?  "Yes" :  "No",
            createdAt: formatDate(element.createdAt),
            updatedAt: formatDate(element.updatedAt)
          };
        });
      },
      (error) => {
        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = error.error.error;
        }
      }
    );

  }

  setHeader(inspectionId) {
    this.inspectionsService.getInspection(inspectionId).subscribe(
      (response: any) => {
        this.buildingAddress = response.data.building.address;
        this.inspectorName =
          response.data.building.inspector.name +
          " " +
          response.data.building.inspector.lastName;
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.error;
      }
    );
  
  }

  redirectBuildings() {
    this.router.navigate(["administration/buildings"]);
  }

  redirectInspections() {
    this.router.navigate(["/administration/inspections"]);
  }

  back() {
    window.history.back();
  }
}
