import { Component, OnInit } from "@angular/core";
import { LocationsService } from "../../services/locations.service";
import { UsersService } from "../../services/users.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: "excel-upload-component",
  templateUrl: "./excel-upload.component.html",
  styleUrls: ["./excel-upload.component.css"],
})
export class ExcelUploadComponent implements OnInit {
  uploadFile = null;
  isSuccess: boolean = false;
  isError: boolean = false;
  inspectorSelected: {
    id: string;
  } = { id: "" };
  isLoading: boolean = false;
  successMessage: string = "";
  errorMessage: string = "";
  disableButton: boolean = true;
  currentRole: string;

  isValid = () =>
    this.inspectorSelected &&
    this.inspectorSelected.id &&
    this.uploadFile != null;

  constructor(
    private locationsService: LocationsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "manager" || this.currentRole === "admin") {
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }

  }

  onSubmit() {
   
      this.isLoading = true;
      this.isError = false;
      let formData = new FormData();
      const payload = {
        id: this.inspectorSelected.id,
        formData: formData,
      };
      formData.append("file", this.uploadFile);

    if (this.isValid()) { 
      this.locationsService.postFile(payload).subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isSuccess = true;
          this.successMessage = response.message;
          this.router.navigate(["administration/buildings"]);
        },
        (error) => {
          this.errorMessage = "";
          this.isLoading = false;
          this.isError = true;

          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
            console.log("error distinto de 403")
            console.log(this.isError)
          }

        }
      );
    }
  }

  onUploadChange(event) {
    this.isError = false;
    this.errorMessage = ""
    this.isSuccess = false;
    this.uploadFile = event.target.files[0];

    if (this.isValid()) {
      this.disableButton = false;
      this.isError = false;

    } else {
      this.disableButton = true;
    }
  }

  receiveInspector($event) {
    this.inspectorSelected.id = $event.value;

    if (this.isValid()) {
      this.disableButton = false;

    } else {
      this.disableButton = true;
    }

  }
}
