import { Component, OnInit, ViewChild } from "@angular/core";
import { InspectionsService } from "../../services/inspections.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { element } from "protractor";
import { BuildingsService } from "../../services/buildings.service";
import { InspectionsModalComponent } from "../../components/inspections-modal/inspections-modal.component";
import { formatDate } from "../../utils/formatDate";

@Component({
  selector: "inspections-filters-component",
  templateUrl: "./inspections.component.html",
  styleUrls: ["./inspections.component.css"],
})
export class InspectionsComponent implements OnInit {
  inspectionsData: any;
  currentPage: number = 1;
  pageCount: number;
  isError: boolean = false;
  errorMessage: string = "";
  optionSelected: string = "";
  space: string = " ";
  isVisible: boolean = false;
  currentInspectorId: string = undefined;
  currentStatus: string;
  currentRole: string;
  isLoading: boolean = false;
  isReopenLoading: boolean = false;
  index: number;
  buildingInspections: any;
  isExist: boolean = false;
  buildingId: string = "";
  createdAt: string;
  @ViewChild(PaginationComponent)
  private paginationComponent: PaginationComponent;
  @ViewChild(InspectionsModalComponent)
  private inspectionsModalComponent: InspectionsModalComponent;

  constructor(
    private inspectionsService: InspectionsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "manager" || this.currentRole === "admin") {
      this.setData("pending", 1, undefined);
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }

  setData(status, page, inspectorId) {
    this.isLoading = true;
    this.isError = false;
    this.currentStatus = status;
    this.currentPage = page;
    this.currentInspectorId = inspectorId;

    this.inspectionsService
      .getInspectionsFilteredByStatusAndInspectorIdWithPagination(
        this.currentStatus,
        this.currentPage,
        this.currentInspectorId
      )
      .subscribe(
        (response: any) => {
          this.inspectionsData = response.data;
          this.isLoading = false;
          this.inspectionsData.map((inspection) => {

            if (inspection.types) {
              inspection.types.map((element, i) => {
                if (i === 0) inspection.type = "";
                if (i === 1 || i === 2) inspection.type += " / ";
                inspection.type += element.type;
              });
            }
            
          });
          this.inspectionsData = this.inspectionsData.map((element) => {
            return {
              ...element,
              createdAt: formatDate(element.createdAt)
            };
          });

          this.paginationComponent.setPagination(
            response.pageCount,
            response.currentPage,
            response.data.length
          );

          if (response.data.length === 0) {
            this.isError = true;
            this.errorMessage = "No inspection found";
          } else {
            this.isError = false;
            this.errorMessage = "";
          }
        },
        (error) => {
          this.isLoading = false;
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.statusText;
          }
        }
      );
  }

  setStatus($event) {
    this.inspectionsData = [];
    this.paginationComponent.setPagination(1, 1, 1);
    this.setData($event, 1, this.currentInspectorId);
  }

  setPage($event) {
    this.setData(this.currentStatus, $event, this.currentInspectorId);
  }

  setInspector($event) {
    this.paginationComponent.setPagination(1, 1, 1);
    this.inspectionsData = [];
    this.setData(this.currentStatus, 1, $event.value);
  }

  navigateDetail(inspectionId) {
    this.router.navigate(["administration/inspection", inspectionId]);
  }

  editInspection(inspectionId) {
    this.router.navigate(["/administration/edit/inspection/" + inspectionId]);
  }
}
