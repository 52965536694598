import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "create-report-modal",
  templateUrl: "./create-report-modal.component.html",
  styleUrls: ["./create-report-modal.component.css"],
})
export class CreateReportModalComponent implements OnInit {
  currentId: string;
  data: any = {};
  isError: boolean = false;
  isLoading: boolean;

  @Output() createEvent = new EventEmitter();
  @Input() service: string;
  @Input() id?: string;

  @ViewChild("noteElement") noteElement: ElementRef;
  @ViewChild("fileElement") fileElement: ElementRef;
  @ViewChild("modal") modalElement: ElementRef;

  constructor(private reports: ReportsService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.id) {
      this.currentId = this.id;
    } else {
      this.currentId =
        this.route.snapshot.params.serviceCallId ||
        this.route.snapshot.params.inspectionId;
    }
  }

  createReport() {
    this.isLoading = true;
    switch (this.service) {
      case "inspection": {
        this.data.inspectionId = this.currentId;
        break;
      }
      case "serviceCall": {
        this.data.serviceCallId = this.currentId;
        break;
      }
      default:
        break;
    }
    this.reports.createReport(this.data).subscribe(
      (response) => {
        this.isLoading = false;
        this.createEvent.emit();
        this.isError = false;
        this.resetFields();
      },
      (error) => {
        this.isLoading = false;
        this.isError = true;
      }
    );
  }

  resetFields() {
    this.noteElement.nativeElement.value = "";
    this.fileElement.nativeElement.value = "";
  }

  onNoteChange($event: any) {
    if ($event.target.value !== "" && $event.target.value !== undefined) {
      this.data.note = $event.target.value;
    }
  }
  onFileSelected($event: any) {
    const file = $event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      setFile(fileReader.result);
    };

    const setFile = (file) => {
      this.data.image = file.replace(/^data:image\/\w+;base64,/, "");
    };
  }
}
