import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BuildingsService } from "../../services/buildings.service";
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { InspectionsService } from "../../services/inspections.service";
import { MapComponent } from "../../components/map/map.component";
import { ServiceCallService } from "../../services/service-call.service";
import { TMService } from "../../services/tm.service";
import { formatDate } from "../../utils/formatDate";

@Component({
  selector: "buildings-component",
  templateUrl: "./buildings.component.html",
  styleUrls: ["./buildings.component.css"],
})
export class BuildingsComponent implements OnInit {
  buildingsData: any;
  buildingSelected: any;
  currentPage: number = 1;
  pageCount: number;
  isError: boolean = false;
  isVisible: boolean = false;
  inspector: any;
  errorMessage: string = "";
  currentInspectorId: string;
  currentFilter: string;
  currentRole: string;
  currentInspectorFilter: string;
  title: string;
  locations: { address: string; cityName: string }[];
  mapLocations: { address: string; cityName: string }[];
  isList: boolean = true;
  isButtonMap: boolean = false;
  isLoading: boolean = false;
  isOther: boolean = false;
  isSpetialAttention: boolean = false;

  currentStatus: string = "pending";


  @ViewChild(PaginationComponent)
  private paginationComponent: PaginationComponent;

  @ViewChild(MapComponent)
  private mapComponent: MapComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private buildingsService: BuildingsService,
    private inspectionsService: InspectionsService,
    private serviceCallService: ServiceCallService,
    private TMService: TMService
  ) { }

  ngOnInit() {
    this.currentRole = localStorage.getItem("USER_ROLE");
    this.title = this.route.snapshot.url[1].path;

    switch (this.currentRole) {
      case "admin": {
        this.currentPage = 1;
        this.isOther = true;
        this.setDataAdmins(
          this.currentInspectorId,
          this.currentFilter,
          this.currentPage
        );
        break;
      }
      case "manager": {
        this.currentPage = 1;
        this.isOther = true;
        this.setDataAdmins(
          this.currentInspectorId,
          this.currentFilter,
          this.currentPage
        );
        break;
      }
      case "inspector": {
        
        if (this.title === "inspections") {
          this.currentPage = 1;
          this.currentStatus= 'pending';

          this.setDataInspectorInspectionsMap();
          this.setDataInspectorInspections(
            this.currentPage,
            this.currentStatus
          );
        }
        if (this.title === "service-calls") {
          this.currentPage = 1;
          this.currentStatus= 'pending';

          this.setDataInspectorServiceCallsMap()
          this.setDataInspectorServiceCalls(
            this.currentPage,
            this.currentStatus
            );
        }
        if (this.title === "T&M") {
          this.currentPage = 1;
          this.currentStatus= 'pending';

          this.setDataInspectorTMMap()
          this.setDataInspectorTM(
            this.currentPage,
            this.currentStatus
            );
        }
        break;
      }
      default: {
        this.router.navigate(["login"]);
        break;
      }
    }

    if (
      this.title === "buildings" ||
      this.title === "inspections" ||
      this.title === "service-calls" ||
      this.title === "T&M"
    ) {
    } else {
      this.router.navigate(["page-not-found"]);
    }
  }

  setDataAdmins(inspectorId, filter, page) {
    this.isError = false;
    this.currentInspectorId = inspectorId;
    this.currentPage = page;
    this.isLoading = true;

    if (filter === "") {
      this.currentFilter = undefined;
    } else {
      this.currentFilter = filter;
    }

    this.buildingsService
      .getBuildingsFilteredWithPagination(
        this.currentInspectorId,
        this.currentPage,
        this.currentFilter
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.setData(response);
        },
        (error) => {
          this.isLoading = false;
          this.setError(error);
        }
      );
  }

  setDataInspectorInspections(page, status) {
    this.isLoading = true;

    this.inspectionsService.getInspectorInspections(page, status).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.setData(response);
        this.setLocations();
      },
      (error) => {
        this.isLoading = false;
        this.setError(error);
      }
    );
  }
  setDataInspectorServiceCalls(page, status) {
    this.isLoading = true;

    this.serviceCallService.getInspectorServiceCalls(page, status).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.setData(response);
        this.setLocations();
      },
      (error) => {
        this.isLoading = false;
        this.setError(error);
      }
    );
  }
  setStatus($event) {
    this.currentStatus = $event
    this.setDataInspectorInspections(1, $event)
  }
  setStatusSC($event) {
    this.currentStatus = $event
    this.setDataInspectorServiceCalls(1, $event)
  }
  setStatusTM($event) {
    this.currentStatus = $event
    this.setDataInspectorTM(1, $event)
  }


  setDataInspectorInspectionsMap() {
    this.isLoading = true;
    this.inspectionsService.getInspectorAllInspections().subscribe(
      (response: any) => {
        this.isLoading = false;
        this.setMapLocations(response.data);
      },
      (error) => {
        this.isLoading = false;
        this.setError(error);
      }
    );
  }



  setDataInspectorServiceCallsMap() {
    this.isLoading = true;
    this.serviceCallService.getInspectorAllServiceCalls().subscribe(
      (response: any) => {
        this.isLoading = false;
        this.setMapLocations(response.data);
      },
      (error) => {
        this.isLoading = false;
        this.setError(error);
      }
    );
  }

  setDataInspectorTM(page, status) {
    this.isLoading = true;
    this.TMService.getInspectorTM(page, status).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.setData(response);
        this.setLocations();
      },
      (error) => {
        this.isLoading = false;
        this.setError(error);
      }
    );
  }

  setDataInspectorTMMap() {
    this.isLoading = true;
    this.TMService.getInspectorAllTM().subscribe(
      (response: any) => {
        this.isLoading = false;
        this.setMapLocations(response.data);
      },
      (error) => {
        this.isLoading = false;
        this.setError(error);
      }
    );
  }

  setData(response) {

    this.paginationComponent.setPagination(
      response.pageCount,
      response.currentPage,
      response.data.length
    );

    this.buildingsData = response.data;
    this.isVisible = true;
    this.buildingsData = this.buildingsData.map((element) => {
      if (element.inspections !== undefined && element.inspections.length > 0) {
        return {
          ...element,
          lastInspectionStatus: element.inspections[0].status,
          lastNoAccessInspection: formatDate(element.lastNoAccessInspection),
          createdAt: formatDate(element.inspections[0].createdAt)


        };
      } else return {
        ...element,

      };
    });

    this.buildingsData = this.buildingsData.map((element) => {
      if (
        element.serviceCalls !== undefined &&
        element.serviceCalls.length > 0
      ) {
        return {
          ...element,
          lastServiceCallStatus: element.serviceCalls[0].status,
          dueDate: formatDate(element.serviceCalls[0].dueDate),
          createdAt: formatDate(element.serviceCalls[0].createdAt)
        };
      } else return { ...element };
    });

    if (this.buildingsData.length === 0) {
      this.isError = true;
      this.isButtonMap = false;
      this.errorMessage = "No building found";
    } else {
      this.isButtonMap = true;
      this.isError = false;
      this.errorMessage = "";
    }
  }

  setLocations() {
    this.locations = this.buildingsData.map((element) => {

      let keyNumber = element.keyNumber ? element.keyNumber : " - "
      let entryCode = element.entryCode ? element.entryCode : " - "
      let address = element.address ? element.address : " - "
      let routingNumber = element.routingNumber ? element.routingNumber : " - "

      var mapData =
        "#%Key Number: " +
        keyNumber +
        "#%Entry Code: " +
        entryCode +
        "#%Address: " +
        address +
        "#%Routing Number: " +
        routingNumber

      if (element.inspections) {
        return {
          inspection: element.inspections[0].status,
          address: element.address,
          keyNumber: element.keyNumber,
          entryCode: element.entryCode,
          cityName: element.cityName,
          data: mapData
        };
      } else {
        return {
          address: element.address,
          cityName: element.cityName,
          data: mapData
        };
      }
    });
  }

  setMapLocations(buildingsData) {
    this.mapLocations = buildingsData.map((element) => {
      let keyNumber = element.keyNumber ? element.keyNumber : " - "
      let entryCode = element.entryCode ? element.entryCode : " - "
      let address = element.address ? element.address : " - "
      let routingNumber = element.routingNumber ? element.routingNumber : " - "

      var mapData =
        "#%Key Number: " +
        keyNumber +
        "#%Entry Code: " +
        entryCode +
        "#%Address: " +
        address +
        "#%Routing Number: " +
        routingNumber

      if (element.inspections) {
        return {
          inspection: element.inspections[0].status,
          address: element.address,
          cityName: element.cityName,
          data: mapData
        };
      } else {
        return {
          address: element.address,
          cityName: element.cityName,
          data: mapData
        };
      }
    });
    this.mapComponent.setData(this.mapLocations);
  }

  setError(error) {
    this.isError = true;
    if (error.status === 403) {
      this.errorMessage = "Unauthorized access.";
    } else {
      this.errorMessage = error.statusText;
    }
    this.isVisible = false;
  }

  onFilter() {
    this.buildingsData = [];
    this.setDataAdmins(this.currentInspectorId, this.currentFilter, 1);
  }


  setPage($event) {
    this.currentPage = $event;

    switch (this.title) {
      case "inspections": {
        this.setDataInspectorInspections(
          this.currentPage,
          this.currentStatus
        );
        break;
      }
      case "service-calls": {
        this.setDataInspectorServiceCalls(this.currentStatus,this.currentPage);
        break;
      }
      case "T&M": {
        this.setDataInspectorTM(this.currentPage, this.currentStatus);
        break;
      }
      case "buildings": {
        this.setDataAdmins(
          this.currentInspectorId,
          this.currentFilter,
          this.currentPage
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  setInspector($event) {
    this.buildingsData = [];
    this.setDataAdmins($event.value, this.currentFilter, 1);
  }

  onSwitch() {
    this.isList = !this.isList;
  }

  onServiceCall(buildingId) {
    this.router.navigate([
      "administration/building",
      buildingId,
      "service-calls",
    ]);
  }

  onInspections(buildingId) {
    this.router.navigate([
      "administration/building",
      buildingId,
      "inspections",
    ]);
  }

  onEdit(buildingId) {
    this.buildingSelected = this.buildingsData.find((data: any) => {
      return data.id === buildingId;
    });

    this.router.navigate(["/administration/edit/building/", buildingId]);
  }

  onCreate() {
    this.router.navigate(["administration/create/building"]);
  }

  selectRow(index) {
    const buildingId = this.buildingsData[index].id;
    this.router.navigate(["inspector/building", buildingId], {
      queryParams: { tab: this.title },
    });
  }
}
