import { Component, OnInit } from "@angular/core";
import { UsersService } from "../../services/users.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: "update-inspector-component",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.css"],
})
export class EditUserComponent implements OnInit {
  updateUser: any = {};
  placeholderValue: any = {};
  isError: boolean = false;
  currentUser: any = {};
  userRole: string;
  userId: string;
  errorMessage: string = "";
  isInvalidUser = true;
  currentRole: string;
  isLoading: boolean;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userRole = params.get("userRole");
    });

    this.userId = this.route.snapshot.params.userId;
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (
      (this.userRole === "service-man" && this.currentRole === "manager") ||
      (this.userRole === "service-man" && this.currentRole === "admin") ||
      (this.userRole === "crew-leader" && this.currentRole === "manager") ||
      (this.userRole === "crew-leader" && this.currentRole === "admin") ||
      (this.userRole === "inspector" && this.currentRole === "manager") ||
      (this.userRole === "inspector" && this.currentRole === "admin") ||
      (this.userRole === "manager" && this.currentRole === "admin")
    ) {
      this.usersService.getUser(this.userRole, this.userId).subscribe(
        (response: any) => {
          this.isError = false;
          this.isInvalidUser = false;
          if (this.userRole !== "service-man") {
            this.placeholderValue = {
              name: response.data.name,
              lastName: response.data.lastName,
              email: response.data.email,
              password: "********",
            };
          } else {
            this.placeholderValue = {
              fullName: response.data.fullName,
            };
          }
        },
        (error) => {
          if (error.status === 400 || error.status === 404) {
            this.isInvalidUser = true;
          }
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
          }
          this.errorMessage =
            this.errorMessage.charAt(0).toUpperCase() +
            this.errorMessage.slice(1);
          this.isError = true;
        }
      );
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }

  onUpdate() {
    this.isLoading = true;
    const payload = {
      id: this.userId,
      updateData: this.updateUser,
    };
    this.usersService.updateUser(payload, this.userRole).subscribe(
      (response) => {
        this.isLoading = false;
        this.isError = false;

        if (this.userRole === "service-man") {
          this.router.navigate(["administration/service-men"]);
        } else {
          this.router.navigate(["administration", this.userRole + "s"]);
        }
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
        this.errorMessage = error.error.error;
        this.isError = true;
      }
    );
  }

  back() {
    window.history.back();
  }
}
