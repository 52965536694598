import { Component, OnInit, ViewChild } from "@angular/core";
import { ServiceCallService } from "../../services/service-call.service";
import { Router, ActivatedRoute } from "@angular/router";
import { formatDate } from "../../utils/formatDate";
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { BuildingsService } from "../../services/buildings.service";
import { formatDueDate } from "../../utils/formatDate";


@Component({
  selector: "building-service-calls",
  templateUrl: "./building-service-calls.component.html",
  styleUrls: ["./building-service-calls.component.css"],
})
export class BuildingServiceCallsComponent implements OnInit {
  serviceCalls: any;
  isError: boolean = false;
  inspectorName: string = "";
  buildingAddress: string = "";
  errorMessage: string = "";
  isVisible: boolean = false;
  currentPage: number;
  pageCount: number;
  currentStatus: string;
  currentBuildingId: string;
  currentRole: string;
  index: number;
  isDeleting: boolean = false;
  isLoading: boolean = false;

  @ViewChild(PaginationComponent)
  private paginationComponent: PaginationComponent;

  constructor(
    private serviceCallService: ServiceCallService,
    private router: Router,
    private route: ActivatedRoute,
    private buildingsService: BuildingsService
  ) {}

  ngOnInit() {
    this.currentBuildingId = this.route.snapshot.params.buildingId;
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "admin" || this.currentRole === "manager") {
      this.setData("pending", 1);
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }

    this.buildingsService.getBuildingById(this.currentBuildingId).subscribe(
      (response: any) => {
        this.buildingAddress = response.data.address;
        this.inspectorName =
          response.data.inspector.name + " " + response.data.inspector.lastName;
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.error;
      }
    );
  }

  setData(status, page) {
    this.currentStatus = status;
    this.currentPage = page;
    this.isError = false;
    const payload = {
      buildingId: this.currentBuildingId,
      status: this.currentStatus,
      page: this.currentPage,
    };

    this.serviceCallService
      .getServiceCallsFilteredWithPagination(payload)
      .subscribe(
        (response: any) => {
          this.serviceCalls = response.data;
          this.serviceCalls = this.serviceCalls.map((element) => {
            return {
              ...element,
              completionDate: formatDate(element.completionDate),
              dueDate: formatDueDate(element.dueDate),
            };
          });

          this.paginationComponent.setPagination(
            response.pageCount,
            response.currentPage,
            response.data.length
          );

          if (this.serviceCalls.length === 0) {
            this.isError = true;
            this.errorMessage = "No service call found";
          } else {
            this.isError = false;
            this.errorMessage = "";
          }
        },
        (error) => {
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
          }
        }
      );
  }

  onChangeFilter(status) {
    this.serviceCalls = [];
    this.setData(status, 1);
  }

  setPage($event) {
    this.setData(this.currentStatus, $event);
  }

  onCreate() {
    this.router.navigate([
      "administration/building",
      this.currentBuildingId,
      "create",
      "building-service-calls",
    ]);
  }

  onEdit(serviceCallId) {
    this.router.navigate(["administration/edit/service-call", serviceCallId]);
  }

  onDetail(serviceCallId) {
    this.router.navigate(["administration/service-call", serviceCallId]);
  }

  redirectBuildings() {
    this.router.navigate(["administration/buildings"]);
  }

  deleteServiceCall(serviceCallId, index) {
    if (confirm("Are you sure you want to remove the service call?")) {
      this.isLoading = true;
      this.isDeleting = true;
      this.index = index;

      this.serviceCallService.deleteServiceCall(serviceCallId).subscribe(
        (response) => {
          this.isDeleting = false;
          this.isLoading = false;
          this.setData(this.currentStatus, 1);
        },
        (error) => {
          this.isDeleting = false;
          this.isLoading = false;
          this.isError = true;
          this.errorMessage = error.error.error;
        }
      );
    }
  }

  back() {
    window.history.back();
  }
}
