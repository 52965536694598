import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CategoriesService } from "../../services/categories.service";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.css"],
})
export class CategoriesComponent implements OnInit {
  categoriesData: any = [];
  currentRole: string;
  categorySelected: any;
  isLoading: boolean = false;
  isError: boolean;
  errorMessage: string;
  isDeleting: boolean = false;
  index: number;
  categoriesDataName: string;

  constructor(
    private router: Router,
    private categoriesService: CategoriesService
  ) { }

  ngOnInit(): void {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "admin" || this.currentRole === "manager") {
      this.setData();
    } else {
      this.router.navigate(["not-found-page"]);
    }
  }

  setData() {
    this.isLoading = true;
    this.isError = false;
    this.categoriesService.getCategories().subscribe(
      (response: any) => {
        this.isLoading = false;
        this.categoriesData = response.data;
        this.categoriesDataName = response.data.name;
        if (this.categoriesData.length === 0) {
          this.isError = true;
          this.errorMessage = "No category found";
        } else {
          this.isError = false;
        }
      },
      (error) => {
        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = "An error occurred while loading categories.";
        }
        this.isLoading = false;
      }
    );
  }

  onCreate() {
    this.router.navigate(["/administration/create/category"]);
  }

  onEdit(idSelected) {
    this.categorySelected = this.categoriesData.find((data) => {
      return data.id === idSelected;
    });

    this.router.navigate(["/administration/edit/category", idSelected]);
  }
  onDelete(categoryId) {
    let categorySelected = this.categoriesData.find((data) => {
      return data.id === categoryId;
    });
    
    if (confirm("Are you sure you want to remove the Category " + categorySelected.name + "?")) {
      this.isLoading = true;
      this.isDeleting = true;

      this.categoriesService
        .deleteCategory(categoryId)
        .subscribe(
          (response) => {
            this.isDeleting = false;
            this.isLoading = false;
            this.setData();
          },
          (error) => {
            this.isDeleting = false;
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = error.error.error;
          }
        );
    }
  }
}
