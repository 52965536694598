import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "sider-component",
  templateUrl: "./sider.component.html",
  styleUrls: ["./sider.component.css"],
})
export class SiderComponent implements OnInit {
  userRole: string = "";

  constructor(private router: Router) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("USER_ROLE");
  }

  showDashboard() {
    this.router.navigate([""]);
  }

  showExcelUpload() {
    this.router.navigate(["administration/inspections/excel-upload"]);
  }

  showInspectors() {
    this.router.navigate(["administration/inspectors"]);
  }

  showManagers() {
    this.router.navigate(["administration/managers"]);
  }

  showServiceMen() {
    this.router.navigate(["administration/service-men"]);
  }

  showCategory() {
    this.router.navigate(["administration/categories"]);
  }

  showAdmins() {
    this.router.navigate(["administration/admins"]);
  }

  showCrewLeaders() {
    this.router.navigate(["administration/crew-leaders"]);
  }

  showInspections() {
    this.router.navigate(["administration/inspections"]);
  }

  showServiceCalls() {
    this.router.navigate(["administration/service-calls"]);
  }

  showCrews() {
    this.router.navigate(["administration/crews"]);
  }

  showBuildings() {
    this.router.navigate(["administration/buildings"]);
  }

  showInspectorInspections() {
    this.router.navigate(["inspector/inspections"]);
  }
  showInspectorServiceCalls() {
    this.router.navigate(["inspector/service-calls"]);
  }
  showInspectorTM() {
    this.router.navigate(["inspector/T&M"]);
  }
}
