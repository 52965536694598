import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InspectionsService } from "../../services/inspections.service";
import { BuildingsService } from "../../services/buildings.service";
import { formatDate } from "../../utils/formatDate";
import { PaginationComponent } from "../../components/pagination/pagination.component";

@Component({
  selector: "building-inspections",
  templateUrl: "./building-inspections.component.html",
  styleUrls: ["./building-inspections.component.css"],
})
export class BuildingInspectionsComponent implements OnInit {
  inspectionsData: any = [];
  buildingAddress: string = "";
  inspectorName: string = "";
  currentBuildingId: string;
  currentStatus: string;
  isError: boolean = false;
  errorMessage: string = "";
  isAvailable: boolean = false;
  currentRole: string;
  isReopenLoading: boolean = false;
  isLoading: boolean = false;
  index: number;
  currentPage: number = 1;

  @ViewChild(PaginationComponent)
  private paginationComponent: PaginationComponent;

  constructor(
    private buildingsService: BuildingsService,
    private inspectionsService: InspectionsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentRole = localStorage.getItem("USER_ROLE");
    this.currentBuildingId = this.route.snapshot.params.buildingId;

    if (this.currentRole === "admin" || this.currentRole === "manager") {
      this.setData("pending", 1);
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }

  setData(status, page) {
    this.isError = false;
    this.currentStatus = status;
    this.isLoading = true;
    this.currentPage = page;

    this.buildingsService.getBuildingById(this.currentBuildingId).subscribe(
      (response: any) => {
        this.buildingAddress = response.data.address;
        this.inspectorName =
          response.data.inspector.name + " " + response.data.inspector.lastName;
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.error;
      }
    );

    this.inspectionsService
      .getInspectionsFilteredByStatusAndBuildingIdWithPagination(
        this.currentBuildingId,
        this.currentStatus,
        this.currentPage,
        this.currentRole
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.inspectionsData = response.data;
          this.inspectionsData = this.inspectionsData.map((element) => {
            return {
              ...element,
              completionDate: formatDate(element.completionDate),
              createdAt: formatDate(element.createdAt)
            };
          });

          this.paginationComponent.setPagination(
            response.pageCount,
            response.currentPage,
            response.data.length
          );
          if (this.inspectionsData.length === 0) {
            this.isError = true;
            this.errorMessage = "No inspection found";
          } else {
            this.isError = false;
            this.errorMessage = "";
          }
        },
        (error) => {
          this.isLoading = false;
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
          }
        }
      );
  }

  setStatus(status) {
    this.inspectionsData = [];
    this.setData(status, this.currentPage);
  }

  setPage($event) {
    this.setData(this.currentStatus, $event);
  }

  editInspection(inspectionId) {
    this.router.navigate(["/administration/edit/inspection/" + inspectionId]);
  }

  navigateDetail(inspectionId) {
    this.router.navigate(["administration/inspection", inspectionId]);
  }

  onTM(inspectionId) {
    this.router.navigate([
      "administration/inspection",
      inspectionId,
      "building-tm",
    ]);
  }

  redirectBuildings() {
    this.router.navigate(["administration/buildings"]);
  }

  back() {
    window.history.back();
  }
}
