import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class InspectionsService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getInspection(inspectionId) {
    return this.http.get(
      this.baseUrl + "/api/v2/bo/administration/inspection/" + inspectionId
    );
  }

  getInspectionsFilteredByStatusAndBuildingIdWithPagination(
    buildingId,
    status,
    page,
    userRole
  ) {
    let params = new HttpParams();

    params = params.append("status", status);
    params = params.append("page", page);

    let url: string = "";

    if (userRole === "admin" || userRole === "manager") {
      url =
        "/api/v2/bo/administration/buildings/" +
        buildingId +
        "/inspections/paginated";
    }

    if (userRole === "inspector") {
      url = "/api/v2/bo/buildings/" + buildingId + "/inspections";
    }

    return this.http.get(this.baseUrl + url, { params });
  }

  getInspectionsFilteredByStatusAndInspectorIdWithPagination(
    status,
    page,
    inspectorId
  ) {
    let params = new HttpParams().append("page", page);

    if (inspectorId !== undefined && inspectorId !== "") {
      params = params.append("inspectorId", inspectorId);
    }

    return this.http.get(
      this.baseUrl + "/api/v2/bo/administration/inspections/list/" + status,
      { params }
    );
  }

  getInspectionFilteredByBuildingId(buildingId, userRole) {
    return this.http.get(
      this.baseUrl +
        "/api/v2/bo/administration/buildings/" +
        buildingId +
        "/inspections"
    );
  }

  updateInspection(payload, inspectionId) {
    return this.http.patch(
      this.baseUrl + "/api/v2/bo/administration/inspection/" + inspectionId,
      payload
    );
  }

  getInspectorInspections(page, status) {
    let params = new HttpParams();

    params = params.append("page", page);
    params = params.append("status", status);

    return this.http.get(this.baseUrl + "/api/v2/bo/inspections/paginated/status", { params });
  }

  getInspectorAllInspections() {
    return this.http.get(this.baseUrl + "/api/v2/bo/inspections/");
  }

  updateInspectorInspection(inspectionId, payload) {
    return this.http.patch(
      this.baseUrl + "/api/v2/bo/inspections/" + inspectionId,
      payload
    );
  }

  reopenInspection(inspectionId, userRole) {
    let url: string = "";

    if (userRole === "admin" || userRole === "manager") {
      url = "/api/v2/bo/administration/inspection/" + inspectionId + "/reopen";
    }

    if (userRole === "inspector") {
      url = "/api/v2/bo/inspections/" + inspectionId + "/reopen";
    }

    return this.http.patch(this.baseUrl + url, {});
  }

  getInspectionNotesByBuildingId(buildingId) {
    return this.http.get(
      this.baseUrl + "/api/v2/bo/building/" + buildingId + "/notes"
    );
  }

  getInspectionCopy(inspectionId, userRole) {
    let url: string = "";

    if (userRole === "admin" || userRole === "manager") {
      url =
        "/api/v2/bo/administration/inspection/" + inspectionId + "/copyPreview";
    }
    if (userRole === "inspector") {
      url = "/api/v2/bo/inspections/" + inspectionId + "/copyPreview";
    }

    return this.http.get(this.baseUrl + url);
  }

  copyInspection(inspectionId, userRole) {
    let url: string = "";

    if (userRole === "admin" || userRole === "manager") {
      url = "/api/v2/bo/administration/inspection/" + inspectionId + "/copy";
    }
    if (userRole === "inspector") {
      url = "/api/v2/bo/inspections/" + inspectionId + "/copy";
    }

    return this.http.post(this.baseUrl + url, {});
  }
}
