import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.css"],
})
export class PaginationComponent implements OnInit {
  isError: boolean = false;
  errorMessage: string = "";
  nextPagePagination: number;
  nextNextPagePagination: number;
  previousPagePagination: number;
  previousPreviousPagePagination: number;
  enablePreviousPagePagination: boolean = false;
  enablePreviousPreviousPagePagination: boolean = false;
  enableNextPagePagination: boolean = false;
  enableNextNextPagePagination: boolean = false;
  enablePagination: boolean = true;

  dataCount: number = 0;
  pageCount: number;
  currentPage: number;
  isDisable: boolean = false;

  @Input() isOther: boolean;
  @Output() pageEvent = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {
    this.setPages();
  }

  setPagination(pageCount: number, currentPage: number, dataCount: number) {
    this.pageCount = pageCount;
    this.currentPage = currentPage;
    this.dataCount = dataCount;
    this.setPages();
  }

  changePage(pages) {
    if (this.currentPage <= this.pageCount) {
      this.currentPage = this.currentPage + pages;
      this.setPages();
    }
    this.pageEvent.emit(this.currentPage);
  }

  setPages() {
    this.nextPagePagination = this.currentPage + 1;
    this.nextNextPagePagination = this.nextPagePagination + 1;
    this.previousPagePagination = this.currentPage - 1;
    this.previousPreviousPagePagination = this.previousPagePagination - 1;

    if (this.previousPagePagination >= 1) {
      this.enablePreviousPagePagination = true;
    } else {
      this.enablePreviousPagePagination = false;
    }

    if (this.previousPreviousPagePagination >= 1) {
      this.enablePreviousPreviousPagePagination = true;
    } else {
      this.enablePreviousPreviousPagePagination = false;
    }

    if (this.currentPage < this.pageCount) {
      this.enableNextPagePagination = true;
    } else {
      this.enableNextPagePagination = false;
    }

    if (this.currentPage < this.pageCount - 1) {
      this.enableNextNextPagePagination = true;
    } else {
      this.enableNextNextPagePagination = false;
    }

    if (this.pageCount === 1 || this.dataCount === 0) {
      this.enablePagination = false;
    } else {
      this.enablePagination = true;
    }
  }
}
