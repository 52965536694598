import { Component, Input, OnInit } from "@angular/core";
import { formatDate } from "../../utils/formatDate";
import { InspectionsService } from "../../services/inspections.service";

@Component({
  selector: "app-inspections-modal",
  templateUrl: "./inspections-modal.component.html",
  styleUrls: ["./inspections-modal.component.css"],
})
export class InspectionsModalComponent implements OnInit {
  isError: boolean = false;
  errorMessage: string = "";
  isWorking: boolean = false;
  isExist: boolean;
  inspections: any[];
  isLoading: boolean = false;
  destinationId: string = "";
  sourceId: string = "";
  currentRole: string = "";

  constructor(private inspectionsService: InspectionsService) {}

  ngOnInit(): void {}

  setData(buildingId, destinationId, currentRole) {
    /* this.inspections = [];
       this.isLoading = true;
       this.isError = false;
       this.destinationId = destinationId;
       this.currentRole = currentRole;
  
       this.inspectionsService
         .getInspectionFilteredByBuildingId(buildingId, this.currentRole)
         .subscribe(
           (response: any) => {
             this.inspections = response.data;
  
             this.inspections.reverse();
             this.inspections.map((inspection) => {
               this.isExist = true;
               this.isWorking = true;
               inspection.updatedAt = formatDate(inspection.updatedAt);
               inspection.types.map((element) => {
                 if (element.serviceIsWorking === undefined) {
                   this.isExist = false;
                 }
                 if (element.serviceIsWorking === false) {
                   this.isWorking = false;
                 }
               });
               if (this.isExist === true) {
                 if (this.isWorking === true) {
                   inspection.isWorking = "Yes";
                 } else {
                   inspection.isWorking = "No";
                 }
               }
               this.isLoading = false;
  
               if (this.inspections.length === 0) {
                 this.isError = true;
                 this.errorMessage = "No service call found";
               } else {
                 this.isError = false;
                 this.errorMessage = "";
               }
             });
           },
           (error) => {
             this.isLoading = true;
             this.isError = true;
             this.errorMessage = error.error.error;
           }
         );*/
  }

  copyInspection(sourceId) {
    /* this.sourceId = sourceId;
  
      this.inspectionsService
        .copyInspection(this.sourceId, this.destinationId)
        .subscribe(
          (response) => {},
          (error) => {}
        );*/
  }
}
