import { Component, OnInit } from "@angular/core";
import { ServiceCallService } from "../../services/service-call.service";
import { ActivatedRoute, Router } from "@angular/router";
import { formatDate } from "../../utils/formatDate";
import { formatDueDate } from "../../utils/formatDate";

@Component({
  selector: "detail-inspection",
  templateUrl: "./detail-service-call.component.html",
  styleUrls: ["./detail-service-call.component.css"],
})
export class DetailServiceCallComponent implements OnInit {
  serviceCall: any;
  serviceCallId: string;
  errorMessage: string;
  isError: boolean = false;
  inspectorName: string = "";
  buildingName: string = "";
  formatDate: string;
  dueDate: string;
  currentReports: any;
  currentRole: string;

  constructor(
    private serviceCallService: ServiceCallService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "manager" || this.currentRole === "admin") {
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }

    this.serviceCallId = this.route.snapshot.params.serviceCallId;
    this.isError = false;
    this.serviceCallService.getServiceCall(this.serviceCallId).subscribe(
      (response: any) => {
        this.serviceCall = response.data;
        this.currentReports = response.data.images;
        this.buildingName = response.data.building.address;
        this.formatDate = formatDate(response.data.completionDate);
        this.dueDate = formatDueDate(response.data.dueDate);
      },
      (error) => {
        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = error.error.error;
        }
      }
    );
  }

  redirectBuildings() {
    this.router.navigate(["administration/buildings"]);
  }

  back() {
    window.history.back();
  }
}
