import { Component, OnInit, ViewChild } from "@angular/core";
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { ServiceCallService } from "../../services/service-call.service";
import { Router } from "@angular/router";
import { formatBDDate, formatDueDate } from "../../utils/formatDate";
import { formatDate } from "../../utils/formatDate";


@Component({
  selector: "app-service-calls",
  templateUrl: "./service-calls.component.html",
  styleUrls: ["./service-calls.component.css"],
})
export class ServiceCallsComponent implements OnInit {
  serviceCallsData: any = [];
  isLoading: boolean = false;
  isError: boolean = false;
  currentStatus: string = "pending";
  currentPage: number = 1;
  currentInspectorId: string;
  currentKeyword: string;
  currentDueDate: string;
  errorMessage: string;
  currentOrder: string = "priority";
  currentRole: string = "";

  @ViewChild(PaginationComponent)
  private paginationComponent: PaginationComponent;

  constructor(
    private serviceCallsService: ServiceCallService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "manager" || this.currentRole === "admin") {
      this.setData(
        this.currentStatus,
        this.currentInspectorId,
        this.currentKeyword,
        this.currentDueDate,
        this.currentPage
      );
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }

  setData(status, inspectorId, keyword, dueDate, page) {
    this.isLoading = true;
    this.isError = false;
    this.currentStatus = status;
    this.currentPage = page;
    this.currentInspectorId = inspectorId;
    this.currentKeyword = keyword;
    this.currentDueDate = dueDate;

    this.serviceCallsService
      .getServiceCallsFilteredByStatusInspectorIdAndKeywordWithPagination(
        this.currentStatus,
        this.currentInspectorId,
        this.currentKeyword,
        this.currentDueDate,
        this.currentPage,
        this.currentOrder
      )
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.serviceCallsData = response.data.map( function(element){
            element.dueDate = formatDueDate(element.dueDate)
            element.completionDate = formatDate(element.completionDate)
            element.createdAt = formatDate(element.createdAt)
            return element
          })

          this.paginationComponent.setPagination(
            response.pageCount,
            response.currentPage,
            response.data.length
          );

          if (response.data.length === 0) {
            this.isError = true;
            this.errorMessage = "No service call found";
          } else {
            this.isError = false;
            this.errorMessage = "";
          }
        },
        (error) => {
          this.isLoading = false;
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
          }
        }
      );
  }

  setPage($event) {
    this.setData(
      this.currentStatus,
      this.currentInspectorId,
      this.currentKeyword,
      this.currentDueDate,
      $event
    );
  }

  setStatus($event) {
    this.paginationComponent.setPagination(1, 1, 1);
    this.serviceCallsData = [];
    this.setData($event, this.currentInspectorId, this.currentKeyword,this.currentDueDate, 1);
  }

  setKeyword() {
    this.paginationComponent.setPagination(1, 1, 1);
    this.serviceCallsData = [];
    this.setData(
      this.currentStatus,
      this.currentInspectorId,
      this.currentKeyword,
      this.currentDueDate,
      1
    );
  }
  
  setDate() {
    this.paginationComponent.setPagination(1, 1, 1);
    this.serviceCallsData = [];
    this.setData(
      this.currentStatus,
      this.currentInspectorId,
      this.currentKeyword,
      this.currentDueDate= formatBDDate(this.currentDueDate),
      1
    );
  }

  setOrder() {
    this.paginationComponent.setPagination(1, 1, 1);
    this.serviceCallsData = [];
    this.setData(
      this.currentStatus,
      this.currentInspectorId,
      this.currentKeyword,
      this.currentDueDate,
      1
    );
  }

  setInspector($event) {
    this.paginationComponent.setPagination(1, 1, 1);
    this.serviceCallsData = [];
    this.setData(this.currentStatus, $event.value, this.currentKeyword,this.currentDueDate, 1);
  }

  onDetail(serviceCallId) {
    this.router.navigate(["administration/service-call", serviceCallId]);
  }

  onEdit(serviceCallId) {
    this.router.navigate(["administration/edit/service-call", serviceCallId]);
  }
}
