import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Admin } from "../models/admin";
import { environment } from "../../environments/environment";
import { formatBDDate } from "../utils/formatDate";
@Injectable({
  providedIn: "root",
})
export class ServiceCallService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getServiceCall(serviceCallId) {
    return this.http.get(
      this.baseUrl + "/api/v2/bo/administration/serviceCall/" + serviceCallId
    );
  }

  getServiceCallsFilteredWithPagination(payload) {
    const params = new HttpParams()
      .append("status", payload.status)
      .append("page", payload.page);

    return this.http.get(
      this.baseUrl +
        "/api/v2/bo/administration/buildings/" +
        payload.buildingId +
        "/serviceCalls",
      { params }
    );
  }

  createServiceCall(payload) {
    return this.http.post(
      this.baseUrl + "/api/v2/bo/administration/serviceCall/register",
      payload
    );
  }

  deleteServiceCall(serviceCallId) {
    return this.http.delete(
      this.baseUrl + "/api/v2/bo/administration/serviceCall/" + serviceCallId
    );
  }

  updateServiceCall(serviceCallId, payload) {
    return this.http.patch(
      this.baseUrl + "/api/v2/bo/administration/serviceCall/" + serviceCallId,
      payload
    );
  }

  approveServiceCall(serviceCallId) {
    return this.http.patch(
      this.baseUrl +
        "/api/v2/bo/administration/serviceCall/" +
        serviceCallId +
        "/approve",
      {}
    );
  }

  getInspectorAllServiceCalls() {
    return this.http.get(this.baseUrl + "/api/v2/bo/serviceCalls");
  }


  getInspectorServiceCalls(page,status) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("status", status);

    return this.http.get(this.baseUrl + "/api/v2/bo/serviceCalls/paginated", { params });
  }

  getServiceCallsFilteredByStatusInspectorIdAndKeywordWithPagination(
    status,
    inspectorId,
    keyword,
    dueDate,
    page,
    order
  ) {
    let params = new HttpParams().append("page", page).append("orderBy", order);

    if (inspectorId !== "" && inspectorId !== undefined) {
      params = params.append("inspectorId", inspectorId);
    }

    if (keyword !== undefined && keyword !== "") {
      params = params.append("keyword", keyword);
    }
    if (dueDate !== undefined && dueDate !== "") {
      dueDate = formatBDDate(dueDate)
      params = params.append("dueDate", dueDate);
    }

    return this.http.get(
      this.baseUrl +
        "/api/v2/bo/administration/serviceCalls/paginated/" +
        status,
      { params }
    );
  }
}
