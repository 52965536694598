import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  private baseUrl = environment.apiUrl;
  currentRole: string;
  constructor(private http: HttpClient) {}

  createReport(payload) {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "inspector") {
      return this.http.post(
        this.baseUrl + "/api/v2/bo/report/register",
        payload
      );
    }
    if (this.currentRole === "manager" || this.currentRole === "admin") {
      return this.http.post(
        this.baseUrl + "/api/v2/bo/administration/report/register",
        payload
      );
    }
  }

  getInspectorInspectionReportsByBuildingId(id) {
    return this.http.get(
      this.baseUrl + "/api/v2/bo/building/" + id + "/reports"
    );
  }

  getInspectorReportsById(type, id) {
    return this.http.get(
      this.baseUrl + "/api/v2/bo/" + type + "/" + id + "/reports"
    );
  }

  editReport(payload) {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "inspector") {
      return this.http.patch(this.baseUrl + "/api/v2/bo/report/" + payload.id, {
        note: payload.note,
      });
    }

    if (this.currentRole === "manager" || this.currentRole === "admin") {
      return this.http.patch(
        this.baseUrl + "/api/v2/bo/administration/report/" + payload.id,
        {
          note: payload.note,
        }
      );
    }
  }

  deleteReport(id) {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "inspector") {
      return this.http.delete(this.baseUrl + "/api/v2/bo/report/" + id);
    }

    if (this.currentRole === "manager" || this.currentRole === "admin") {
      return this.http.delete(
        this.baseUrl + "/api/v2/bo/administration/report/" + id
      );
    }
  }
}
