import { Component, Input, OnInit } from "@angular/core";
import { ServiceCallService } from "../../services/service-call.service";
import { formatDate } from "../../utils/formatDate";

@Component({
  selector: "app-detail-service-call-modal",
  templateUrl: "./detail-service-call-modal.component.html",
  styleUrls: ["./detail-service-call-modal.component.css"],
})
export class DetailServiceCallModalComponent implements OnInit {
  isLoading: boolean = false;
  isError: boolean = false;
  errorMessage: string = "";
  serviceCall: any = {};
  currentReports: {}[];
  formatDate: string;
  isExist: boolean;

  constructor(private serviceCallService: ServiceCallService) {}

  ngOnInit(): void {}

  setData(serviceCall) {
    this.serviceCall = serviceCall;
    this.formatDate = formatDate(serviceCall.completionDate);
    this.currentReports = this.serviceCall.images;
  }
}
