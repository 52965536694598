import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "reports-table",
  templateUrl: "./reports-table.component.html",
  styleUrls: ["./reports-table.component.css"],
})
export class ReportsTableComponent implements OnInit {
  dataEdit: any = { note: "" };
  inspectorName: string;
  isError: boolean = false;
  errorMessage: string = "";

  @Input() detail: any;
  @Input() edit: boolean;

  @Output() deleteEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter<any>();

  constructor(private reportsService: ReportsService) {}

  ngOnInit(): void {
    if (this.detail.length === 0) {
      this.isError = true;
      this.errorMessage = "No image found";
    } else {
      this.isError = false;
      this.errorMessage = "";
    }
  }

  captureReport(data) {
    this.dataEdit = { ...data };
    this.editEvent.emit(this.dataEdit);
  }

  deleteReport(id) {
    if (confirm("Are you sure you want to remove the report?")) {
      this.reportsService.deleteReport(id).subscribe(
        (response) => {
          this.deleteEvent.emit();
        },
        (error) => {}
      );
    }
  }
}
