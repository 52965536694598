import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CrewsService } from "../../services/crews.service";

@Component({
  selector: "app-crews",
  templateUrl: "./crews.component.html",
  styleUrls: ["./crews.component.css"],
})
export class CrewsComponent implements OnInit {
  crewsData: any = [];
  currentRole: string;
  crewSelected: any;
  isLoading: boolean = false;
  isError: boolean;
  errorMessage: string;

  constructor(private router: Router, private crewsService: CrewsService) {}

  ngOnInit(): void {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "admin" || this.currentRole === "manager") {
      this.setData();
    } else {
      this.router.navigate(["not-found-page"]);
    }
  }

  setData() {
    this.isLoading = true;
    this.isError = false;

    this.crewsService.getCrews().subscribe(
      (response: any) => {
        this.isLoading = false;
        this.crewsData = response.data;

        if (this.crewsData.length === 0) {
          this.isError = true;
          this.errorMessage = "No crew found";
        } else {
          this.isError = false;
        }
      },
      (error) => {
        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = "An error occurred while loading crews.";
        }
        this.isLoading = false;
      }
    );
  }

  onCreate() {
    this.router.navigate(["/administration/create/crew"]);
  }

  onEdit(idSelected) {
    this.crewSelected = this.crewsData.find((data) => {
      return data.id === idSelected;
    });

    this.router.navigate(["/administration/edit/crew", idSelected]);
  }
}
