import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InspectionsService } from "../../services/inspections.service";
import { formatDate } from "../../utils/formatDate";
import { InspectionHistoryModalComponent } from "../../components/inspection-history-modal/inspection-history-modal.component";

@Component({
  selector: "detail-inspection",
  templateUrl: "./detail-inspection.component.html",
  styleUrls: ["./detail-inspection.component.css"],
})
export class DetailInspectionComponent implements OnInit {
  inspection: any = { history: [] };
  isError: boolean = false;
  inspectorName: string = "";
  buildingAddress: string = "";
  userRole: string = "";
  errorMessage: string;
  inspectionId: string;
  currentReports: [];
  currentRole: string;
  types: string = "";
  dateTime: string;
  createdAt: string;
  formatDate: string;
  formatCreatedDate: string;
  isLoading: boolean = false;
  isExist: boolean = false;

  @ViewChild(InspectionHistoryModalComponent)
  private inspectionHistoryModalComponent: InspectionHistoryModalComponent;

  constructor(
    private inspectionsService: InspectionsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("USER_ROLE");
    this.setData();

    this.currentRole = localStorage.getItem("USER_ROLE");
    if (this.currentRole === "manager" || this.currentRole === "admin") {
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }

  setData() {
    this.inspectionId = this.route.snapshot.params.inspectionId;
    this.isLoading = true;
    this.isExist = false;

    this.inspectionsService.getInspection(this.inspectionId).subscribe(
      (response: any) => {
        this.isExist = true;
        this.isLoading = false;
        this.inspection = response.data;
        this.currentReports = response.data.images;
        this.dateTime = this.inspection.completionDate;
        this.createdAt = this.inspection.createdAt;
        this.formatDate = formatDate(this.dateTime);
        this.formatCreatedDate = formatDate(this.createdAt);
        this.inspectorName =
          this.inspection.building.inspector.name +
          " " +
          this.inspection.building.inspector.lastName;
        this.buildingAddress = this.inspection.building.address;
        this.inspection.types.map((element, i) => {
          if (i === 1 || i === 2) this.types += " / ";
          this.types += element.type;
        });
      },
      (error) => {
        this.isExist = false;

        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = error.error.error;
        }
      }
    );
  }

  redirectInspections() {
    this.router.navigate(["administration/inspections"]);
  }

  redirectBuildings() {
    this.router.navigate(["administration/buildings"]);
  }

  back() {
    window.history.back();
  }
}
