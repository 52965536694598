import { Component, OnInit } from "@angular/core";
import { UsersService } from "../../services/users.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: "inspectors-component",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  usersData: any = [];
  isError: boolean = false;
  userSelected: any;
  usersRole: string = "";
  userRole: string = "";
  errorMessage: string;
  currentFilter: string;
  currentRole: string;
  isLoading: boolean = false;
  isDeleting: boolean = false;
  index: number;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.usersData = [];
      this.isError = false;
      this.usersRole = params.get("usersRole");
      if (this.usersRole === "service-men") {
        this.userRole = "service-man";
      } else {
        this.userRole = this.usersRole.slice(0, this.usersRole.length - 1);
      }

      this.currentFilter = undefined;

      this.currentRole = localStorage.getItem("USER_ROLE");

      if (
        (this.usersRole === "service-men" && this.currentRole === "manager") ||
        (this.usersRole === "service-men" && this.currentRole === "admin") ||
        (this.usersRole === "inspectors" && this.currentRole === "manager") ||
        (this.usersRole === "inspectors" && this.currentRole === "admin") ||
        (this.usersRole === "crew-leaders" && this.currentRole === "manager") ||
        (this.usersRole === "crew-leaders" && this.currentRole === "admin") ||
        (this.usersRole === "managers" && this.currentRole === "admin") ||
        (this.usersRole === "admins" && this.currentRole === "admin")
      ) {
        this.setData(this.currentFilter);
      } else {
        this.router.navigate(["not-found-page"]);
      }
    });
  }

  onCreate() {
    this.router.navigate(["/administration/create", this.userRole]);
  }

  onEdit(idSelected) {
    this.userSelected = this.usersData.find((data) => {
      return data.id === idSelected;
    });

    this.router.navigate(["/administration/edit", this.userRole, idSelected]);
  }

  onDelete(userId, index) {
    if (confirm("Are you sure you want to remove the " + this.userRole + "?")) {
      this.isLoading = true;
      this.isDeleting = true;
      this.index = index;

      this.usersService
        .deleteUser(userId, this.usersRole.slice(0, this.usersRole.length - 1))
        .subscribe(
          (response) => {
            this.isDeleting = false;
            this.isLoading = false;
            this.setData(this.currentFilter);
          },
          (error) => {
            this.isDeleting = false;
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = error.error.error;
          }
        );
    }
  }

  setData(filter) {
    this.isLoading = true;
    this.currentFilter = filter;
    this.usersService.getUsers(this.usersRole, this.currentFilter).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.usersData = response.data;
        if (this.usersData.length === 0) {
          this.isError = true;
          this.errorMessage = "No " + this.usersRole + " found";
        } else {
          this.isError = false;
        }
      },
      (error) => {
        this.isLoading = false;
        this.usersData = [];
        this.isError = true;

        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage =
            "An error occurred while loading " + this.usersRole + ".";
        }
      }
    );
  }

  onFilter() {
    if (this.currentFilter === "") {
      this.currentFilter = undefined;
    }
    this.setData(this.currentFilter);
  }
}
