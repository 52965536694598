import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";

// Pages
import { LayoutComponent } from "./pages/layout/layout.component";
import { LoginComponent } from "./pages/login/login.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ExcelUploadComponent } from "./pages/excel-upload/excel-upload.component";
import { UsersComponent } from "./pages/users/users.component";
import { CreateUserComponent } from "./pages/create-user/create-user.component";
import { EditUserComponent } from "./pages/edit-user/edit-user.component";
import { BuildingsComponent } from "./pages/buildings/buildings.component";
import { InspectionsComponent } from "./pages/inspections/inspections.component";
import { CreateServiceCallComponent } from "./pages/create-service-call/create-service-call.component";
import { BuildingServiceCallsComponent } from "./pages/building-service-calls/building-service-calls.component";
import { EditInspectionComponent } from "./pages/edit-inspection/edit-inspection.component";
import { DetailServiceCallComponent } from "./pages/detail-service-call/detail-service-call.component";
import { BuildingTmComponent } from "./pages/building-tm/building-tm.component";
import { DetailTMComponent } from "./pages/detail-tm/detail-tm.component";
import { BuildingInspectionsComponent } from "./pages/building-inspections/building-inspections.component";
import { EditServiceCallComponent } from "./pages/edit-service-call/edit-service-call.component";
import { DetailInspectionComponent } from "./pages/detail-inspection/detail-inspection.component";
import { CreateBuildingComponent } from "./pages/create-building/create-building.component";
import { EditBuildingComponent } from "./pages/edit-building/edit-building.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { InspectorDetailBuildingComponent } from "./pages/inspector-detail-building/inspector-detail-building.component";
import { CrewsComponent } from "./pages/crews/crews.component";
import { CreateCrewComponent } from "./pages/create-crew/create-crew.component";
import { EditCrewComponent } from "./pages/edit-crew/edit-crew.component";
import { CategoriesComponent } from "./pages/categories/categories.component";
import { CreateCategoryComponent } from "./pages/create-category/create-category.component";
import { EditCategoryComponent } from "./pages/edit-category/edit-category.component";
import { ServiceCallsComponent } from "./pages/service-calls/service-calls.component";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  

  {
    path: "",
    component: LayoutComponent,
    children: [
      { path: "", component: DashboardComponent },

      // Files

      {
        path: "administration/inspections/excel-upload",
        component: ExcelUploadComponent,
      },

      // Lists

      {
        path: "administration/inspections",
        component: InspectionsComponent,
      },
      {
        path: "administration/service-calls",
        component: ServiceCallsComponent,
      },
      {
        path: "administration/buildings",
        component: BuildingsComponent,
      },
      {
        path: "administration/crews",
        component: CrewsComponent
      },
      {
        path: "administration/categories",
        component: CategoriesComponent
      },
      {
        path: "administration/:usersRole",
        component: UsersComponent
      },
      {
        path: "inspector/inspections",
        component: BuildingsComponent,
      },
      {
        path: "inspector/service-calls",
        component: BuildingsComponent,
      },
      {
        path: "inspector/T&M",
        component: BuildingsComponent,
      },

      // Lists with origin

      {
        path: "administration/building/:buildingId/inspections",
        component: BuildingInspectionsComponent,
      },
      {
        path: "administration/inspection/:inspectionId/building-tm",
        component: BuildingTmComponent,
      },
      {
        path: "administration/building/:buildingId/service-calls",
        component: BuildingServiceCallsComponent,
      },

      // Details

      {
        path: "administration/inspection/:inspectionId",
        component: DetailInspectionComponent,
      },
      {
        path: "administration/building-tm/:TMid",
        component: DetailTMComponent,
      },
      {
        path: "administration/service-call/:serviceCallId",
        component: DetailServiceCallComponent,
      },
      {
        path: "inspector/building/:buildingId",
        component: InspectorDetailBuildingComponent,
      },

      // Creates

      {
        path: "administration/create/crew",
        component: CreateCrewComponent,
      },
      {
        path: "administration/create/category",
        component: CreateCategoryComponent,
      },
      {
        path: "administration/create/building",
        component: CreateBuildingComponent,
      },
      {
        path: "administration/create/:userRole",
        component: CreateUserComponent,
      },
      {
        path:
          "administration/building/:buildingId/create/building-service-calls",
        component: CreateServiceCallComponent,
      },

      // Edits

      {
        path: "administration/edit/crew/:crewId",
        component: EditCrewComponent,
      },
      {
        path: "administration/edit/category/:categoryId",
        component: EditCategoryComponent,
      },

      {
        path: "administration/edit/building/:buildingId",
        component: EditBuildingComponent,
      },
      {
        path: "administration/edit/inspection/:inspectionId",
        component: EditInspectionComponent,
      },
      {
        path: "administration/edit/service-call/:serviceCallId",
        component: EditServiceCallComponent,
      },
      {
        path: "administration/edit/:userRole/:userId",
        component: EditUserComponent,
      },

      // Not found

      {
        path: "**",
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
