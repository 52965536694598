import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getUsers(usersRole, filter) {
    let params = new HttpParams();
    if (filter !== undefined) {
      params = params.append("name", filter);
    }

    if (usersRole === "crew-leaders") usersRole = "crewLeaders";
    if (usersRole === "service-men") usersRole = "serviceMen";

    return this.http.get(
      this.baseUrl + "/api/v2/bo/administration/" + usersRole,
      {
        params,
      }
    );
  }

  getUser(userRole, userId) {
    if (userRole === "service-man") userRole = "serviceMan";
    if (userRole === "crew-leader") userRole = "crewLeader";

    return this.http.get(
      this.baseUrl + "/api/v2/bo/administration/" + userRole + "/" + userId
    );
  }

  createUser(payload, userRole) {
    if (userRole === "crew-leader") userRole = "crewLeader";
    if (userRole === "service-man") userRole = "serviceMan";

    return this.http.post(
      this.baseUrl + "/api/v2/bo/administration/" + userRole + "/register",
      payload
    );
  }

  updateUser(payload, userRole) {
    if (userRole === "crew-leader") userRole = "crewLeader";
    if (userRole === "service-man") userRole = "serviceMan";
    return this.http.patch(
      this.baseUrl + "/api/v2/bo/administration/" + userRole + "/" + payload.id,
      JSON.stringify(payload.updateData)
    );
  }

  deleteUser(userId, userRole) {
    if (userRole === "crew-leader") userRole = "crewLeader";

    return this.http.delete(
      this.baseUrl + "/api/v2/bo/administration/" + userRole + "/" + userId
    );
  }
}
