import { Component, OnInit } from "@angular/core";
import { InspectionsService } from "../../services/inspections.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "edit-inspection",
  templateUrl: "./edit-inspection.component.html",
  styleUrls: ["./edit-inspection.component.css"],
})
export class EditInspectionComponent implements OnInit {
  updateInspection: any = {};
  inspection: any = {};
  types: string = "";
  inspectionId: string = "";
  inspectorName: string = "";
  buildingAddress: string = "";
  errorMessage: string = "";
  isError: boolean;
  isWorking: boolean;
  currentIsWorking: string;
  currentRole: string;
  currentReports: [];
  isLoading: boolean = false;
  isUpdateable: boolean = false;
  payload: { types: { type: string; serviceIsWorking: boolean }[] } = {
    types: [{ type: "", serviceIsWorking: null }],
  };
  payloadCopy: { types: { type: string; serviceIsWorking: boolean }[] } = {
    types: [{ type: "", serviceIsWorking: null }],
  };
  isReopenLoading: boolean = false;
  isExist: boolean = false;

  constructor(
    private inspectionsService: InspectionsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.inspectionId = this.route.snapshot.params.inspectionId;
    this.isError = false;
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "manager" || this.currentRole === "admin") {
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
    this.isExist = false;

    this.inspectionsService.getInspection(this.inspectionId).subscribe(
      (response: any) => {
        this.inspection = response.data;
        this.isExist = true;

        this.types = "";
        this.inspection.types.map((element, i) => {
          if (i === 1 || i === 2) this.types += " / ";

          this.types += element.type;
        });

        this.payload.types = this.inspection.types;
        this.payloadCopy = JSON.parse(JSON.stringify(this.payload));
        this.changeOption();

        this.currentReports = response.data.images;

        if (
          this.currentRole === "admin" ||
          ((this.inspection.status === "completed" ||
            this.inspection.status === "noAccess") &&
            this.currentRole === "manager")
        ) {
          if (this.inspection.serviceIsWorking === true) {
            this.currentIsWorking = "Yes";
          }

          if (this.inspection.serviceIsWorking === false) {
            this.currentIsWorking = "No";
          }

          this.buildingAddress = this.inspection.building.address;
          this.inspectorName =
            this.inspection.building.inspector.name +
            " " +
            this.inspection.building.inspector.lastName;
        } else {
          this.router.navigate(["page-not-found"]);
        }
      },
      (error) => {
        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = error.error.error;
        }
      }
    );
  }

  changeOption() {
    this.isUpdateable =
      JSON.stringify(this.payload) !== JSON.stringify(this.payloadCopy);
  }

  reopenInspection(inspectionId) {
    this.isError = false;

    if (confirm("Are you sure you want to reopen the inspection?")) {
      this.isReopenLoading = true;

      this.inspectionsService
        .reopenInspection(inspectionId, this.currentRole)
        .subscribe(
          (response) => {
            window.history.back();
            this.isError = false;
            this.isReopenLoading = false;
          },
          (error) => {
            this.isReopenLoading = false;
            this.isError = true;
            this.errorMessage = error.error.error;
          }
        );
    }
  }

  onUpdate() {
    this.isLoading = true;

    this.inspectionsService
      .updateInspection(this.payload, this.inspection.id)
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.ngOnInit();
        },
        (error) => {
          this.isLoading = false;
          this.isError = true;
          this.errorMessage = error.error.error;
        }
      );
  }

  redirectBuildings() {
    this.router.navigate(["administration/buildings"]);
  }

  back() {
    window.history.back();
  }
}
