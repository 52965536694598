import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BuildingsService } from "../../services/buildings.service";
import { InspectionsService } from "../../services/inspections.service";
import { ReportsService } from "../../services/reports.service";
import { formatDate } from "../../utils/formatDate";
import set = Reflect.set;
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { InspectionHistoryModalComponent } from "../../components/inspection-history-modal/inspection-history-modal.component";

@Component({
  selector: "app-inspector-detail-building",
  templateUrl: "./inspector-detail-building.component.html",
  styleUrls: ["./inspector-detail-building.component.css"],
})
export class InspectorDetailBuildingComponent implements OnInit {
  currentBuildingId: string;
  buildingData: any = {};
  currentTab: string;
  inspectionId: string;
  serviceCallId: string;
  types: string = "";
  TMId: string;
  currentInspectionReports: {}[];
  currentServiceCallReports: {}[];
  currentInspectionBuildingNotes: {}[];
  completionDate: string = "";
  createdAt: string = "";
  payload: { types: { type: string; serviceIsWorking: boolean }[] } = {
    types: [{ type: "", serviceIsWorking: null }],
  };
  payloadCopy: { types: { type: string; serviceIsWorking: boolean }[] } = {
    types: [{ type: "", serviceIsWorking: null }],
  };
  isUpdateable: boolean = false;
  isLoading: boolean = false;
  isExist: boolean = false;
  isReopenLoading: boolean = false;
  currentRole: string = "";
  isError: boolean = false;
  errorMessage: string = "";
  TMdevice: any

  @ViewChild(InspectionHistoryModalComponent)
  private inspectionHistoryModalComponent: InspectionHistoryModalComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private buildingsService: BuildingsService,
    private inspectionsService: InspectionsService,
    private reportsService: ReportsService
  ) { }

  ngOnInit(): void {
    this.currentBuildingId = this.route.snapshot.params.buildingId;
    this.setBuilding();
    this.currentRole = localStorage.getItem("USER_ROLE");

  }

  setTab(tab) {
    this.currentTab = tab;
  }
  back() {
    window.history.back();
  }

  setBuilding() {
    this.isExist = false;
    this.TMdevice = [];

    this.types = "";
    this.buildingsService
      .getInspectorBuilding(this.currentBuildingId)
      .subscribe(
        (response: any) => {
          this.buildingData = response.data;

          this.buildingData.serviceCalls = this.buildingData.serviceCalls.map((element) => {
            return {
              ...element,
              dueDate: formatDate(element.dueDate),
            };
          });

          if (this.buildingData.inspections[0]) {
            this.inspectionId = this.buildingData.inspections[0].id;
            this.setTypeReports("inspection", this.inspectionId);
            this.buildingData.inspections[0].types.map((types, i) => {
              if (i === 1 || i === 2) this.types += " / ";
              this.types += types.type;
            });

            this.payload.types = this.buildingData.inspections[0].types;
            this.payloadCopy = JSON.parse(JSON.stringify(this.payload));

            this.changeOption();
            this.completionDate = formatDate(
              this.buildingData.inspections[0].completionDate
            );
            this.createdAt = formatDate(
              this.buildingData.inspections[0].createdAt
            );
          }

          if (this.buildingData.serviceCalls[0]) {
            this.serviceCallId = this.buildingData.serviceCalls[0].id;
            this.setTypeReports("serviceCall", this.serviceCallId);
          }

          if (this.buildingData.inspections[0].testingAndMaintenances[0]) {
            this.TMId = this.buildingData.inspections[0].testingAndMaintenances[0].devicesResult.id;
          }
          //Device for T&M
          this.TMdevice = response.data.inspections[0].testingAndMaintenances[0].devicesResult.map((element) => {
            return {
              ...element,
              isDone: element.isDone == true ?  "Yes" :  "No",
              createdAt: formatDate(element.createdAt),
              updatedAt: formatDate(element.updatedAt)
            };
          });

          if (this.TMdevice.length === 0) {
            this.isError = true;
            this.errorMessage = "No building-tm found";
          }

          this.currentTab = this.route.snapshot.queryParams.tab;

          this.isExist = true;
          this.setNotes();
        },
        (error) => {
          this.isExist = false;
        }
      );
  }

  updateInspection() {
    this.isLoading = true;
    this.inspectionsService
      .updateInspectorInspection(this.inspectionId, this.payload)
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.setBuilding();
          this.inspectionHistoryModalComponent.ngOnInit();
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  changeOption() {
    this.isUpdateable =
      JSON.stringify(this.payload) !== JSON.stringify(this.payloadCopy);
  }

  setNotes() {
    this.inspectionsService
      .getInspectionNotesByBuildingId(this.currentBuildingId)
      .subscribe(
        (response: any) => {
          this.currentInspectionBuildingNotes = response.data;

          this.currentInspectionBuildingNotes.map((notes: any) => {
            notes.date = formatDate(notes.date);
          });

          if (this.currentInspectionBuildingNotes.length === 0) {
            this.isError = true;
            this.errorMessage = "No note found";
          } else {
            this.isError = false;
            this.errorMessage = "";
          }
        },
        (error) => { }
      );
  }

  reopenInspection(inspectionId) {
    if (confirm("Are you sure you want to reopen the inspection?")) {
      this.isReopenLoading = true;

      this.inspectionsService
        .reopenInspection(inspectionId, this.currentRole)
        .subscribe(
          (response) => {
            window.history.back();
            this.isReopenLoading = false;
          },
          (error) => {
            this.isReopenLoading = false;
          }
        );
    }
  }

  setTypeReports(type, id) {
    this.reportsService.getInspectorReportsById(type, id).subscribe(
      (response: any) => {
        if (type === "inspection") {
          this.currentInspectionReports = response.data;
        }
        if (type === "serviceCall") {
          this.currentServiceCallReports = response.data;
        }
      },
      (error) => { }
    );
  }

  reloadReports(type, id) {
    if (type === "inspection") {
      this.setNotes();
    }
    this.setTypeReports(type, id);
  }
}
