import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry, tap } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class MainInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers = req.headers;

    if (
      !req.url.endsWith("uploadExcel") &&
      !req.url.endsWith("https://maps.googleapis.com/maps/api/geocode/json")
    ) {
      headers = headers.append("Content-Type", "application/json");
    }

    if (
      localStorage.getItem("ACCESS_TOKEN") &&
      !req.url.endsWith("https://maps.googleapis.com/maps/api/geocode/json")
    ) {
      headers = headers.append(
        "Authorization",
        "Bearer " + localStorage.getItem("ACCESS_TOKEN")
      );
    }

    const reqClone = req.clone({ headers });

    return next.handle(reqClone).pipe(
      retry(2),
      tap({
        error: (error) => {
          if (error.status === 401) {
            localStorage.removeItem("ACCESS_TOKEN");
            localStorage.removeItem("USER_ROLE");
            this.router.navigate(["login"]);
          }
        },
      }),
      catchError(this.catchError)
    );
  }

  catchError(error: HttpErrorResponse) {
    console.error("Error:", error);
    return throwError(error);
  }
}
