import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class TMService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getTMsFiltered(inspectionId, status, page) {
    let params = new HttpParams();

    params = params.append("status", status);
    params = params.append("page", page);

    return this.http.get(
      this.baseUrl +
        "/api/v2/bo/administration/inspections/" +
        inspectionId +
        "/testingAndMaintenance/paginated",
      { params }
    );
  }

  getTM(TMid) {
    return this.http.get(
      this.baseUrl + "/api/v2/bo/administration/testingAndMaintenance/" + TMid
    );
  }

  getInspectorAllTM() {
    return this.http.get(this.baseUrl + "/api/v2/bo/testingAndMaintenances");
  }

  getInspectorTM(page, status) {
    let params = new HttpParams();
    params = params.append("page", page);
    params = params.append("status", status);
    return this.http.get(this.baseUrl + "/api/v2/bo/testingAndMaintenances/paginated", { params });
  }
}
