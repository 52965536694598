import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class BuildingsService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getBuildingsFilteredWithPagination(inspectorId, page, filter) {
    let params = new HttpParams().append("page", page);

    if (inspectorId !== undefined && inspectorId !== "") {
      params = params.append("inspectorId", inspectorId);
    }

    if (filter !== undefined) {
      params = params.append("keyword", filter);
    }

    return this.http.get(
      this.baseUrl + "/api/v2/bo/administration/buildings/paginated",
      {
        params,
      }
    );
  }

  getBuildingById(buildingId) {
    return this.http.get(
      this.baseUrl + "/api/v2/bo/administration/building/" + buildingId
    );
  }

  updateBuilding(payload) {
    return this.http.patch(
      this.baseUrl + "/api/v2/bo/administration/building/" + payload.id,
      JSON.stringify(payload.updateData)
    );
  }

  createBuilding(payload) {
    return this.http.post(
      this.baseUrl + "/api/v2/bo/administration/building/register",
      payload,
      {}
    );
  }

  getInspectorBuilding(buildingId) {
    return this.http.get(this.baseUrl + "/api/v2/bo/building/" + buildingId);
  }
}
