import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CategoriesService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCategories() {
    return this.http.get(this.baseUrl + "/api/v2/bo/administration/category/list");
  }

  getCategoryById(id) {
    return this.http.get(
      this.baseUrl + "/api/v2/bo/administration/category/" + id
    );
  }

  createCategory(category) {
    return this.http.post(
      this.baseUrl + "/api/v2/bo/administration/category/register",
      category
    );
  }

  updateCategory(payload, id) {
    return this.http.patch(
      this.baseUrl + "/api/v2/bo/administration/category/" + id,
      payload
    );
  }
  deleteCategory(categoryId) {
    return this.http.delete(
      this.baseUrl + "/api/v2/bo/administration/category/" + categoryId
    );
  }
}
