import { Component, OnInit } from "@angular/core";
import { UsersService } from "../../services/users.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "create-inspector-component",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.css"],
})
export class CreateUserComponent implements OnInit {
  user: any = {};
  isError: boolean = false;
  isSuccess: boolean = false;
  userRole: string = "";
  errorMessage: string = "";
  currentRole: string;
  usersRole: string = "";
  isLoading: boolean;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.currentRole = localStorage.getItem("USER_ROLE");
    this.userRole = this.route.snapshot.params.userRole;
    if (this.userRole === "service-man") {
      this.usersRole = "service-men";
    } else {
      this.usersRole = this.userRole + "s";
    }

    if (
      (this.userRole === "service-man" && this.currentRole === "manager") ||
      (this.userRole === "service-man" && this.currentRole === "admin") ||
      (this.userRole === "crew-leader" && this.currentRole === "manager") ||
      (this.userRole === "crew-leader" && this.currentRole === "admin") ||
      (this.userRole === "inspector" && this.currentRole === "manager") ||
      (this.userRole === "inspector" && this.currentRole === "admin") ||
      (this.userRole === "manager" && this.currentRole === "admin")
    ) {
    } else {
      this.router.navigate(["not-found-page"]);
    }
  }

  submit() {
    this.isLoading = true;
    this.isError = false;
    this.usersService.createUser(this.user, this.userRole).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.isSuccess = true;
        this.isError = false;
        this.router.navigate(["/administration/", this.usersRole]);
      },
      (error) => {
        this.isLoading = false;

        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = error.error.error;
        }
      }
    );
  }

  back() {
    window.history.back();
  }
}
