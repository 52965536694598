import { Component, OnInit } from "@angular/core";
import { LoginService } from "src/app/services/login.service";
import { Router } from "@angular/router";

@Component({
  selector: "navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  userName: string = "";

  constructor(private loginService: LoginService, private router: Router) {}

  ngOnInit() {
    this.userName = localStorage.getItem("USER_NAME");
  }

  redirectHome() {
    this.router.navigate([""]);
  }

  onLogout() {
    this.loginService.logout().subscribe(
      (response) => {
        this.onExit();
      },
      (error) => {
        this.onExit();
      }
    );
  }

  onExit() {
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("USER_ROLE");
    localStorage.removeItem("USER_NAME");

    this.router.navigate(["login"]);
  }
}
