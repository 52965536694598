import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  postLogin(user) {
    return this.http.post(this.baseUrl + "/api/v2/bo/login", user);
  }

  logout() {
    return this.http.post(this.baseUrl + "/api/v2/bo/logout", {});
  }
}
