import * as moment from "moment";

export const formatDate = (date) => {
  return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : null;
}
export const formatDueDate = (date) => {
  return date ? moment(date).format("MM-DD-YYYY") : null;
}

export const formatBDDate = (date) => {
  return date ? moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ") : null;
}