import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LocationsService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  postFile(payload) {
    return this.http.post(
      this.baseUrl + "/api/v2/bo/administration/" + payload.id + "/uploadExcel",
      payload.formData
    );
  }

  getLatitudeAndLongitude(location) {
    const params = new HttpParams()
      .append("address", location)
      .append("key", "AIzaSyC9TDEBvBmGolKbaxPUVJPqWXx8Hq31KVU");
    return this.http.get("https://maps.googleapis.com/maps/api/geocode/json", {
      params,
    });
  }
}
