import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { LocationsService } from "../../services/locations.service";

@Component({
  selector: "map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.css"],
})
export class MapComponent implements OnInit {
  title: string;
  lat: number = 0;
  lng: number = 0;
  markers: {
    inspection: string;
    lat: number;
    lng: number;
    data: string;
  }[] = [];
  icon: {}[];

  @Output() markerDoubleClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private locationsService: LocationsService) { }

  ngOnInit(): void {
    this.title = "Google Maps";
    this.lat = 40.73061; // New York latitude
    this.lng = -73.935242; // New York longitude
    this.icon = [
      // Completed
      {
        url: "../../../assets/marker-green.png",
        scaledSize: {
          width: 30,
          height: 30,
        },
      },
      // Pending
      {
        url: "../../../assets/marker-red.png",
        scaledSize: {
          width: 30,
          height: 30,
        },
      },
      // No access
      {
        url: "../../../assets/marker-orange.png",
        scaledSize: {
          width: 30,
          height: 30,
        },
      },
    ];
  }

  setData(locations) {
    this.markers = [];
    console.log(this.markers)
    locations.forEach((location) => {
      let address = "";
      let cityName = "";
      let keyNumber = "";
      let entryCode = "";
      if (location.keyNumber) keyNumber = location.keyNumber;
      if (location.entryCode) entryCode = location.entryCode;
      if (location.address) address = location.address;
      if (location.cityName) cityName = location.cityName;

      this.locationsService
        .getLatitudeAndLongitude(address + ", " + cityName + ", US")
        .subscribe(
          (response: any) => {
            this.markers.push({
              ...response.results[0].geometry.location,
              data: location.data,
              inspection: location.inspection,
            });
          },
          (error) => {
            console.log(error)
           }
        );
    });
  }

  selectBuilding(e) {
    this.markerDoubleClick.emit(e);
  }
}
