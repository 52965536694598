import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "edit-report-modal",
  templateUrl: "./edit-report-modal.component.html",
  styleUrls: ["./edit-report-modal.component.css"],
})
export class EditReportModalComponent implements OnInit {
  dataEdit: any = { note: "" };
  isError: boolean = false;

  @Input() id: string;

  @Output() editEvent = new EventEmitter();

  constructor(private reportsService: ReportsService) {}

  ngOnInit(): void {}

  setNote($event) {
    this.dataEdit = $event;
  }

  editNote() {
    this.reportsService.editReport(this.dataEdit).subscribe(
      (response) => {
        this.editEvent.emit();
      },
      (error) => {}
    );
  }
}
