import { Component, OnInit } from "@angular/core";
import { Building } from "../../models/building";
import { BuildingsService } from "../../services/buildings.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-create-building",
  templateUrl: "./create-building.component.html",
  styleUrls: ["./create-building.component.css"],
})
export class CreateBuildingComponent implements OnInit {
  building: any = {};
  isError: boolean = false;
  isSuccess: boolean = false;
  errorMessage: string = "";
  currentRole: string;
  isLoading: boolean;

  constructor(
    private buildingsService: BuildingsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "manager" || this.currentRole === "admin") {
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }

  submit() {
    const payload = JSON.stringify(this.building);
    this.isLoading = true;
    this.buildingsService.createBuilding(payload).subscribe(
      (response) => {
        this.isSuccess = true;
        this.isLoading = false;
        this.router.navigate(["administration/buildings"]);
      },
      (error) => {
        this.isLoading = false;

        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = error.error.error;
        }
      }
    );
  }

  setInspector($event) {
    this.building.inspectorId = $event.value;
  }

  back() {
    window.history.back();
  }
}
