import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ServiceCallService } from "../../services/service-call.service";
import { BuildingsService } from "../../services/buildings.service";
import { CrewsService } from "../../services/crews.service";
import { DropdownComponent } from "../../components/dropdown/dropdown.component";
import { CategoriesService } from "../../services/categories.service";
import { formatBDDate } from "../../utils/formatDate";

@Component({
  selector: "create-service-call",
  templateUrl: "./create-service-call.component.html",
  styleUrls: ["./create-service-call.component.css"],
})
export class CreateServiceCallComponent implements OnInit {

  public dueDate = 'mm/dd/yyyy'
  public mask = [/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]


  serviceCallData: any = {};
  sourceCategory: any = [];
  isError: boolean = false;
  errorMessage: string;
  inspectorName: string;
  buildingAddress: string;
  currentBuildingId: string;
  currentInspectorId: string;
  currentRole: string;
  isLoading: boolean = false;
  sourcePriority: { value: number; label: string }[] = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ];
  currentPriority: number;
  currentCategory: any;
  selectedCrewId: string;
  teamMemberIds: any = [];
  crewMembers: any = [];
  currentTitle: string;
  currentDescription: string;

  @ViewChild("crewsMembers") private crewsMembersDropdown: DropdownComponent;

  constructor(
    private router: Router,
    private serviceCallService: ServiceCallService,
    private route: ActivatedRoute,
    private buildingsService: BuildingsService,
    private crewsService: CrewsService,
    private categoriesService: CategoriesService
  ) {}

  ngOnInit() {
    this.currentBuildingId = this.route.snapshot.params.buildingId;

    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "manager" || this.currentRole === "admin") {
      this.setData();
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }

  setData() {
    this.isLoading = true;
    if (this.currentBuildingId) {
      this.buildingsService.getBuildingById(this.currentBuildingId).subscribe(
        (response: any) => {
          this.isLoading = false;
          this.inspectorName =
            response.data.inspector.name +
            " " +
            response.data.inspector.lastName;
          this.buildingAddress = response.data.address;
          this.currentInspectorId = response.data.inspector.id;
        },
        (error) => {
          this.isLoading = false;
          this.isError = true;
          this.errorMessage = error.error.error;
        }
      );

      this.categoriesService.getCategories().subscribe(
        (response: any) => {
          this.isLoading = false;
          let temp = []
          response.data.forEach(category => {
            console.log(category)
            temp.push({
              value: category,
              label: category.name
            })
          });

          this.sourceCategory = temp

          if (this.sourceCategory.length === 0) {
            this.isError = true;
            this.errorMessage = "No category found";
          } else {
            this.isError = false;
          }
        },
        (error) => {
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = "An error occurred while loading categories.";
          }
          this.isLoading = false;
        }
      );
    }
  }

  updatePriority($event) {
    this.currentPriority = $event.value;
  }

  updateCategory($event) {
    this.currentCategory = $event.value;
  }

  updateCrew($event) {
    this.selectedCrewId = $event.value;

    this.crewsService.getCrewById(this.selectedCrewId).subscribe(
      (response: any) => {
        let members: string[] = [];

        response.data.teamHistory[0].members.map((element, index) => {
          members.push(element.id);

          if (response.data.teamHistory[0].members.length - 1 === index) {
            this.teamMemberIds = members;
            this.crewsMembersDropdown.ngOnInit();
          }
        });
      },

      (error) => {}
    );
  }

  updateCrewMembersId($event) {
    this.crewMembers = $event.value;
  }

  updateTitle($event) {
    this.currentTitle = $event.target.value;
  }

  updateDescription($event) {
    this.currentDescription = $event.target.value;
  }

  onCreate() {
    this.serviceCallData = {};
    this.serviceCallData.title = this.currentTitle;
    this.serviceCallData.dueDate = formatBDDate(this.dueDate);
    this.serviceCallData.descriptionSC = this.currentDescription;
    this.serviceCallData.buildingId = this.currentBuildingId;
    this.serviceCallData.priority = this.currentPriority;
    this.serviceCallData.categoryId = this.currentCategory.id;

    if (this.currentCategory.name == '6') {
      this.serviceCallData.inspectorId = this.currentInspectorId;
    }

    if (this.currentCategory.name != '6') {
      this.serviceCallData.crewId = this.selectedCrewId;
      this.serviceCallData.teamMemberIds = this.crewMembers;
    }

    const payload = JSON.stringify(this.serviceCallData);

    this.isLoading = true;

    this.serviceCallService.createServiceCall(payload).subscribe(
      (response) => {
        this.isLoading = false;
        this.isError = false;
        this.router.navigate([
          "administration/building",
          this.currentBuildingId,
          "service-calls",
        ]);
      },

      (error) => {
        this.isLoading = false;
        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = error.error.error;
          this.errorMessage =
            this.errorMessage.charAt(0).toUpperCase() +
            this.errorMessage.slice(1);
        }
      }
    );
  }

  back() {
    window.history.back();
  }
}
