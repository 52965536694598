import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CrewsService } from "../../services/crews.service";

@Component({
  selector: "app-edit-crew",
  templateUrl: "./edit-crew.component.html",
  styleUrls: ["./edit-crew.component.css"],
})
export class EditCrewComponent implements OnInit {
  crewId: string;
  currentRole: string;
  placeholderValue: any = {};
  isError: boolean;
  errorMessage: string;
  updateLeaderId: string;
  updateMemberIds: string[];
  updateName: string;
  isLoading: boolean = false;
  isExist: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private crewsService: CrewsService
  ) {}

  ngOnInit(): void {
    this.crewId = this.route.snapshot.params.crewId;
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "manager" || this.currentRole === "admin") {
      this.crewsService.getCrewById(this.crewId).subscribe(
        (response: any) => {
          this.isExist = true;
          let members: string[] = [];

          response.data.teamHistory[0].members.map((element) => {
            members.push(element.id);
          });

          this.placeholderValue = {
            name: response.data.name,
            crewLeaderId: response.data.teamHistory[0].leader.id,
            teamMemberIds: members,
          };
        },

        (error) => {
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
            this.errorMessage =
              this.errorMessage.charAt(0).toUpperCase() +
              this.errorMessage.slice(1);
          }
        }
      );
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }

  setCrewLeader($event) {
    this.updateLeaderId = $event.value;
  }

  setTeam($event) {
    this.updateMemberIds = $event.value;
  }

  onUpdate() {
    this.isError = false;
    this.isLoading = true;
    let updateCrew: any = {};

    if (
      this.updateLeaderId !== this.placeholderValue.crewLeaderId &&
      this.updateLeaderId !== undefined
    ) {
      updateCrew.leaderId = this.updateLeaderId;
    }

    if (this.updateMemberIds !== this.placeholderValue.teamMemberIds) {
      updateCrew.teamMemberIds = this.updateMemberIds;
    }

    if (
      this.updateName !== this.placeholderValue.name &&
      this.updateName !== undefined &&
      this.updateName !== ""
    ) {
      updateCrew.name = this.updateName;
    }

    this.crewsService.updateCrew(updateCrew, this.crewId).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.router.navigate(["/administration/crews"]);
      },
      (error) => {
        this.isLoading = false;
        this.isError = true;
        this.errorMessage = error.error.error;
      }
    );
  }

  back() {
    window.history.back();
  }
}
