import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BuildingsService } from "../../services/buildings.service";

@Component({
  selector: "app-edit-building",
  templateUrl: "./edit-building.component.html",
  styleUrls: ["./edit-building.component.css"],
})
export class EditBuildingComponent implements OnInit {
  placeholderValue: {
    address: string;
    routingNumber: string;
    accountNumber: string;
    zipCode: string;
    cityName: string;
    contactInfo: string;
    entryCode: string;
    inspectorId: string;
  } = {
    address: "",
    routingNumber: "",
    accountNumber: "",
    zipCode: "",
    cityName: "",
    contactInfo: "",
    entryCode: "",
    inspectorId: "",
  };
  updateBuilding: any = {};
  isError: boolean;
  errorMessage: string = "";
  buildingId: string;
  currentRole: string;
  isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private buildingsService: BuildingsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.buildingId = this.route.snapshot.params.buildingId;
    this.currentRole = localStorage.getItem("USER_ROLE");
    this.isLoading = true;
    if (this.currentRole === "manager" || this.currentRole === "admin") {
      this.buildingsService.getBuildingById(this.buildingId).subscribe(
        (response: any) => {
          this.isLoading = false;

          this.placeholderValue = {
            address: response.data.address,
            routingNumber: response.data.routingNumber,
            accountNumber: response.data.accountNumber,
            zipCode: response.data.zipCode,
            cityName: response.data.cityName,
            contactInfo: response.data.contactInfo,
            entryCode: response.data.entryCode,
            inspectorId: response.data.inspector.id,
          };
        },
        (error) => {
          this.isLoading = false;
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
            this.errorMessage =
              this.errorMessage.charAt(0).toUpperCase() +
              this.errorMessage.slice(1);
          }
        }
      );
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }

  setInspector($event) {
    this.updateBuilding.inspectorId = $event.value;
  }

  onUpdate() {
    const payload = {
      id: this.buildingId,
      updateData: this.updateBuilding,
    };
    this.isLoading = true;

    this.buildingsService.updateBuilding(payload).subscribe(
      (response) => {
        this.isLoading = false;

        this.router.navigate(["/administration/buildings"]);
      },
      (error) => {
        this.isLoading = false;

        this.isError = true;
        this.errorMessage = error.error.message;
      }
    );
  }

  back() {
    window.history.back();
  }
}
