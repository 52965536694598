import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "../../services/login.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "login-component",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  isEmpty: boolean = false;
  emailEmpty: boolean = false;
  passwordEmpty: boolean = false;
  invalidUser: boolean = false;
  token: string = "";
  userRole: string = "";
  isError: boolean;
  errorMessage: string;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
        ],
      ],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  onInput($event: any) {
    if ($event.target.value !== "") {
      this.isEmpty = false;
      this.emailEmpty = false;
      this.passwordEmpty = false;
      this.invalidUser = false;
    }
  }

  submitLogin() {
    const val = this.form.value;
    if (val.email && val.password) {
      const payload = JSON.stringify(val);
      this.loginService.postLogin(payload).subscribe(
        (response: any) => {
          localStorage.setItem(
            "ACCESS_TOKEN",
            (this.token = response.data.token)
          );
          localStorage.setItem("USER_ROLE", response.data.user.role);
          localStorage.setItem(
            "USER_NAME",
            response.data.user.name + " " + response.data.user.lastName
          );

          if (localStorage.getItem("USER_ROLE") === "inspector") {
            this.router.navigate(["inspector/inspections"]);
          } else {
            this.router.navigate([""]);
          }
        },
        (error) => {
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
          }

          this.invalidUser = true;
        }
      );
    }

    if (val.email === "" && val.password === "") {
      this.isEmpty = true;
    }

    if (val.email === "" && val.password) {
      this.emailEmpty = true;
    }

    if (val.email && val.password == "") {
      this.passwordEmpty = true;
    }
  }
}
