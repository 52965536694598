import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AgmCoreModule } from "@agm/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

// Pages
import { LoginComponent } from "./pages/login/login.component";
import { LayoutComponent } from "./pages/layout/layout.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ExcelUploadComponent } from "./pages/excel-upload/excel-upload.component";
import { UsersComponent } from "./pages/users/users.component";
import { InspectionsComponent } from "./pages/inspections/inspections.component";
import { BuildingsComponent } from "./pages/buildings/buildings.component";
import { DetailInspectionComponent } from "./pages/detail-inspection/detail-inspection.component";
import { CreateUserComponent } from "./pages/create-user/create-user.component";
import { EditUserComponent } from "./pages/edit-user/edit-user.component";
import { CreateServiceCallComponent } from "./pages/create-service-call/create-service-call.component";
import { BuildingServiceCallsComponent } from "./pages/building-service-calls/building-service-calls.component";
import { EditServiceCallComponent } from "./pages/edit-service-call/edit-service-call.component";
import { EditInspectionComponent } from "./pages/edit-inspection/edit-inspection.component";
import { DetailServiceCallComponent } from "./pages/detail-service-call/detail-service-call.component";
import { BuildingTmComponent } from "./pages/building-tm/building-tm.component";
import { DetailTMComponent } from "./pages/detail-tm/detail-tm.component";
import { BuildingInspectionsComponent } from "./pages/building-inspections/building-inspections.component";
import { CrewsComponent } from "./pages/crews/crews.component";
import { CreateCrewComponent } from "./pages/create-crew/create-crew.component";
import { InspectorDetailBuildingComponent } from "./pages/inspector-detail-building/inspector-detail-building.component";
import { EditCrewComponent } from "./pages/edit-crew/edit-crew.component";
import { CreateCategoryComponent } from "./pages/create-category/create-category.component";
import { CategoriesComponent } from "./pages/categories/categories.component";
import { EditCategoryComponent } from "./pages/edit-category/edit-category.component";

// Components
import { SiderComponent } from "./components/sider/sider.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { ReportsTableComponent } from "./components/reports-table/reports-table.component";
import { EditReportModalComponent } from "./components/edit-report-modal/edit-report-modal.component";
import { CreateReportModalComponent } from "./components/create-report-modal/create-report-modal.component";
import { CreateBuildingComponent } from "./pages/create-building/create-building.component";
import { EditBuildingComponent } from "./pages/edit-building/edit-building.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { MapComponent } from "./components/map/map.component";
import { LoadingButtonComponent } from "./components/loading-button/loading-button.component";
import { InspectionHistoryModalComponent } from "./components/inspection-history-modal/inspection-history-modal.component";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { InspectionsModalComponent } from "./components/inspections-modal/inspections-modal.component";
import { ServiceCallsComponent } from "./pages/service-calls/service-calls.component";
import { CopyModalComponent } from "./components/copy-modal/copy-modal.component";

// External components
import { Select2Module } from "ng-select2-component";

// Interceptors
import { MainInterceptor } from "./interceptors/main.interceptor";
import { DetailServiceCallModalComponent } from "./components/detail-service-call-modal/detail-service-call-modal.component";

// No use
import { TextMaskModule } from "angular2-text-mask";


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SiderComponent,
    NavbarComponent,
    DashboardComponent,
    ExcelUploadComponent,
    UsersComponent,
    LoginComponent,
    InspectionsComponent,
    BuildingsComponent,
    DetailInspectionComponent,
    CreateUserComponent,
    EditUserComponent,
    CreateServiceCallComponent,
    BuildingServiceCallsComponent,
    EditServiceCallComponent,
    EditInspectionComponent,
    DetailServiceCallComponent,
    BuildingTmComponent,
    DetailTMComponent,
    BuildingInspectionsComponent,
    PaginationComponent,
    ReportsTableComponent,
    EditReportModalComponent,
    CreateReportModalComponent,
    CreateBuildingComponent,
    EditBuildingComponent,
    NotFoundComponent,
    MapComponent,
    InspectorDetailBuildingComponent,
    LoadingButtonComponent,
    CrewsComponent,
    InspectionHistoryModalComponent,
    CreateCrewComponent,
    DropdownComponent,
    EditCrewComponent,
    InspectionsModalComponent,
    CopyModalComponent,
    ServiceCallsComponent,
    DetailServiceCallModalComponent,
    CreateCategoryComponent,
    CategoriesComponent,
    EditCategoryComponent,
  ],
  imports: [
    Select2Module,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    TextMaskModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyC9TDEBvBmGolKbaxPUVJPqWXx8Hq31KVU",
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MainInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
