import { Component, OnInit } from "@angular/core";
import { InspectionsService } from "../../services/inspections.service";
import { formatDate } from "../../utils/formatDate";

@Component({
  selector: "app-copy-modal",
  templateUrl: "./copy-modal.component.html",
  styleUrls: ["./copy-modal.component.css"],
})
export class CopyModalComponent implements OnInit {
  isLoading: boolean = false;
  isError: boolean = false;
  errorMessage: string = "";
  userRole: string;
  inspection: any;
  currentReports: [];
  dateTime: string;
  formatDate: string;
  types: string = "";
  isExist: boolean = false;
  inspectionId: string = "";

  constructor(private inspectionsService: InspectionsService) {}

  ngOnInit(): void {
    this.userRole = localStorage.getItem("USER_ROLE");
  }

  setData(inspectionId) {
    this.isLoading = true;
    this.isExist = false;
    this.inspectionId = inspectionId;

    this.inspectionsService
      .getInspectionCopy(this.inspectionId, this.userRole)
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.types = "";
          this.isExist = true;

          if (response.data) {
            this.inspection = response.data;
            this.currentReports = response.data.images;
            this.dateTime = this.inspection.completionDate;
            this.formatDate = formatDate(this.dateTime);
            this.inspection.types.map((element, i) => {
              if (i === 1 || i === 2) this.types += " / ";
              this.types += element.type;
            });
          } else {
            this.isExist = false;
          }
        },
        (error) => {
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
          }
        }
      );
  }

  onCopy() {
    this.isLoading = true;
    this.inspectionsService
      .copyInspection(this.inspectionId, this.userRole)
      .subscribe(
        (response) => {
          ($("#copyModal") as any).modal("hide");
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }
}
