import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "loading-button",
  templateUrl: "./loading-button.component.html",
  styleUrls: ["./loading-button.component.css"],
})
export class LoadingButtonComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() disableButton: boolean;
  @Input() type: string = "primary";
  @Input() title: string;
  @Input() width: number;
  @Output() clickEvent = new EventEmitter<void>();

  buttonType: string;
  constructor() {}

  ngOnInit(): void {
    switch (this.type) {
      case "primary":
        this.buttonType = "btn-secondary";
        break;
      case "secondary":
        this.buttonType = "btn-outline-secondary";
        break;
      case "danger":
        this.buttonType = "btn-outline-danger";
        break;
      default:
        break;
    }
  }

  click() {
    this.clickEvent.emit();
  }
}
