import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CrewsService } from "../../services/crews.service";

@Component({
  selector: "app-create-crew",
  templateUrl: "./create-crew.component.html",
  styleUrls: ["./create-crew.component.css"],
})
export class CreateCrewComponent implements OnInit {
  crew: any = {};
  currentRole: string = "";
  errorMessage: string = "";
  isError: boolean = false;
  isLoading: boolean = false;

  constructor(private router: Router, private crewsService: CrewsService) {}

  ngOnInit(): void {
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "admin" || this.currentRole === "manager") {
    } else {
      this.router.navigate(["not-found-page"]);
    }
  }

  submit() {
    this.isLoading = true;
    this.isError = false;
    this.crewsService.createCrew(this.crew).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.isError = false;
        this.router.navigate(["/administration/crews"]);
      },
      (error) => {
        this.isLoading = false;

        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = error.error.error;
        }
      }
    );
  }

  setCrewLeader($event) {
    this.crew.leaderId = $event.value;
  }

  setServiceMen($event) {
    this.crew.teamMemberIds = $event.value;
  }

  back() {
    window.history.back();
  }
}
