import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ServiceCallService } from "../../services/service-call.service";
import { CrewsService } from "../../services/crews.service";
import { DropdownComponent } from "../../components/dropdown/dropdown.component";
import { formatDate } from '../../utils/formatDate'
import { CategoriesService } from "../../services/categories.service";

@Component({
  selector: "edit-service-call",
  templateUrl: "./edit-service-call.component.html",
  styleUrls: ["./edit-service-call.component.css"],
})
export class EditServiceCallComponent implements OnInit {
  serviceCallUpdateData: any = {};
  placeholder: any = {};
  errorMessage: string;
  isError = false;
  buildingId: string = "";
  inspectorName: string = "";
  buildingAddress: string = "";
  imgId: string = "";
  serviceCallId: string;
  currentReports: [];
  currentRole: string;
  serviceCall: any;
  isLoading: boolean = false;
  isApproveLoading: boolean = false;
  sourceCategory: any = [];
  currentCategory: any;
  selectedCrewId: string;
  teamMemberIds: any = [];
  crewMembers: any = [];

  @ViewChild("crewsMembers") private crewsMembersDropdown: DropdownComponent;

  constructor(
    private serviceCallService: ServiceCallService,
    private router: Router,
    private route: ActivatedRoute,
    private crewsService: CrewsService,
    private categoriesService: CategoriesService

  ) { }

  ngOnInit() {
    this.serviceCallId = this.route.snapshot.params.serviceCallId;

    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "manager" || this.currentRole === "admin") {
      this.setData();
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }
  setData() {
    if (this.serviceCallId) {

      this.serviceCallService.getServiceCall(this.serviceCallId).subscribe(
        (response: any) => {
          this.serviceCall = response.data;
          this.buildingId = response.data.building.id;
          this.buildingAddress = response.data.building.address;
          this.placeholder = response.data;
          this.placeholder.completionDate = formatDate(response.data.completionDate)
          this.currentReports = response.data.images;
          if (this.placeholder.status == 'crewNeeded') {

            this.serviceCall.categoryId = this.currentCategory;
            this.serviceCall.crewId = this.selectedCrewId;
            this.serviceCall.teamMemberIds = this.crewMembers;
          }
        },
        (error) => {
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
          }
        }
      );

      this.categoriesService.getCategories().subscribe(
        (response: any) => {
          this.isLoading = false;
          let temp = []
          response.data.forEach(category => {
            if (category.name != '6') {
              temp.push({
                value: category,
                label: category.name
              })
            }
          });

          this.sourceCategory = temp
          if (this.sourceCategory.length === 0) {
            this.isError = true;
            this.errorMessage = "No category found";
          } else {
            this.isError = false;
          }
        },
        (error) => {
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = "An error occurred while loading categories.";
          }
          this.isLoading = false;
        }
      );


    }
  }

  onUpdate() {
    this.isError = false;

    this.isLoading = true;
    if (this.serviceCallUpdateData.completionDate) {
      this.serviceCallUpdateData.completionDate = new Date(
        this.serviceCallUpdateData.completionDate
      ).toISOString();
    }
    if (this.placeholder.status == 'crewNeeded') {
      this.serviceCallUpdateData.categoryId = this.currentCategory.id;
      this.serviceCallUpdateData.crewId = this.selectedCrewId;
      this.serviceCallUpdateData.teamMemberIds = this.crewMembers;
    }
    this.serviceCallService
      .updateServiceCall(this.serviceCallId, this.serviceCallUpdateData)
      .subscribe(
        (response: any) => {
          this.isLoading = false;

          this.router.navigate([
            "administration/building",
            this.buildingId,
            "service-calls",
          ]);
        },
        (error) => {
          this.isLoading = false;

          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
          }
        }
      );
  }

  redirectBuildings() {
    this.router.navigate(["administration/buildings"]);
  }

  approveServiceCall(serviceCallId) {
    this.isError = false;

    this.isApproveLoading = true;
    this.serviceCallService.approveServiceCall(serviceCallId).subscribe(
      (response) => {
        this.isApproveLoading = false;
        window.history.back();
      },
      (error) => {
        this.isApproveLoading = false;
        this.isError = true;
        this.errorMessage = error.error.error;
      }
    );
  }
  updateCategory($event) {
    this.currentCategory = $event.value;
  }
  updateCrew($event) {
    this.selectedCrewId = $event.value;

    this.crewsService.getCrewById(this.selectedCrewId).subscribe(
      (response: any) => {
        let members: string[] = [];

        response.data.teamHistory[0].members.map((element, index) => {
          members.push(element.id);

          if (response.data.teamHistory[0].members.length - 1 === index) {
            this.teamMemberIds = members;
            this.crewsMembersDropdown.ngOnInit();
          }
        });
      },

      (error) => { }
    );
  }

  updateCrewMembersId($event) {
    this.crewMembers = $event.value;
  }

  back() {
    window.history.back();
  }
}
