import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CrewsService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCrews() {
    return this.http.get(this.baseUrl + "/api/v2/bo/administration/crews");
  }

  getCrewById(crewId) {
    return this.http.get(
      this.baseUrl + "/api/v2/bo/administration/crew/" + crewId
    );
  }

  createCrew(crew) {
    return this.http.post(
      this.baseUrl + "/api/v2/bo/administration/crew/register",
      crew
    );
  }

  updateCrew(payload, id) {
    return this.http.patch(
      this.baseUrl + "/api/v2/bo/administration/crew/" + id,
      payload
    );
  }
}
