import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CategoriesService } from "../../services/categories.service";

@Component({
  selector: "app-edit-category",
  templateUrl: "./edit-category.component.html",
  styleUrls: ["./edit-category.component.css"],
})
export class EditCategoryComponent implements OnInit {
  categoryId: string;
  currentRole: string;
  placeholderValue: any = {};
  isError: boolean;
  errorMessage: string;
  updateLeaderId: string;
  updateMemberIds: string[];
  updateName: string;
  isLoading: boolean = false;
  isExist: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private categoriesService: CategoriesService
  ) {}

  ngOnInit(): void {
    this.categoryId = this.route.snapshot.params.categoryId;
    this.currentRole = localStorage.getItem("USER_ROLE");

    console.log(this.categoryId)

    if (this.currentRole === "manager" || this.currentRole === "admin") {
      this.categoriesService.getCategoryById(this.categoryId).subscribe(
        (response: any) => {
          this.isExist = true;

          console.log(response)
          this.placeholderValue = response.data.name
        },

        (error) => {
          this.isError = true;
          if (error.status === 403) {
            this.errorMessage = "Unauthorized access.";
          } else {
            this.errorMessage = error.error.error;
            this.errorMessage =
              this.errorMessage.charAt(0).toUpperCase() +
              this.errorMessage.slice(1);
          }
        }
      );
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
  }


  onUpdate() {
    this.isError = false;
    this.isLoading = true;
    let updateCategory: any = {};

    if (
      this.updateName !== this.placeholderValue.description &&
      this.updateName !== undefined &&
      this.updateName !== ""
    ) {
      updateCategory.name = this.updateName;
    }

    this.categoriesService.updateCategory(updateCategory, this.categoryId).subscribe(
      (response: any) => {
        this.isLoading = false;
        console.log(updateCategory, response);
        this.router.navigate(["/administration/categories"]);
      },
      (error) => {
        this.isLoading = false;
        this.isError = true;
        this.errorMessage = error.error.error;
      }
    );
  }

  back() {
    window.history.back();
  }
}
