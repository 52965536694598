import { Component, OnInit } from "@angular/core";
import { TMService } from "../../services/tm.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { InspectionsService } from "../../services/inspections.service";
import { formatDate } from "../../utils/formatDate";

@Component({
  selector: "building-tm",
  templateUrl: "./building-tm.component.html",
  styleUrls: ["./building-tm.component.css"],
})
export class BuildingTmComponent implements OnInit {
  TMdata: any;
  isError: boolean = false;

  inspectorName: string = "";
  buildingAddress: string = "";
  inspectionId: string;
  currentStatus: string;
  errorMessage: string;
  currentRole: string;
  currentPage: number = 1;

  constructor(
    private TMService: TMService,
    private route: ActivatedRoute,
    private router: Router,
    private inspectionsService: InspectionsService
  ) {}

  ngOnInit() {
    this.inspectionId = this.route.snapshot.params.inspectionId;
    this.currentRole = localStorage.getItem("USER_ROLE");

    if (this.currentRole === "admin" || this.currentRole === "manager") {
    } else {
      if (this.currentRole === "inspector") {
        this.router.navigate(["not-found-page"]);
      } else {
        this.router.navigate(["login"]);
      }
    }

    this.setData("pending", 1);

    this.inspectionsService.getInspection(this.inspectionId).subscribe(
      (response: any) => {
        this.buildingAddress = response.data.building.address;
        this.inspectorName =
          response.data.building.inspector.name +
          " " +
          response.data.building.inspector.lastName;
      },
      (error) => {
        this.isError = true;
        if (error.status === 403) {
          this.errorMessage = "Unauthorized access.";
        } else {
          this.errorMessage = error.statusText;
        }
      }
    );
  }

  setData(status, page) {
    this.currentStatus = status;
    this.currentPage = page;
    this.isError = false;
    this.TMdata = [];
    this.TMService.getTMsFiltered(
      this.inspectionId,
      this.currentStatus,
      this.currentPage
    ).subscribe(
      (response: any) => {
        this.TMdata = response.data.map((element) => {
          return {
            ...element,
            completionDate: formatDate(element.completionDate),
            createdAt: formatDate(element.createdAt)

          };
        });

        if (this.TMdata.length === 0) {
          this.isError = true;
          this.errorMessage = "no building with testing and maintenances found";
        }
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.error;
      }
    );
  }

  setPage($event) {
    this.setData(this.currentStatus, $event);
  }

  setStatus(status) {
    this.setData(status, 1);
  }

  detailTM(tmId) {
    this.router.navigate(["administration/building-tm", tmId]);
  }

  redirectBuildings() {
    this.router.navigate(["administration/buildings"]);
  }

  back() {
    window.history.back();
  }
}
