import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UsersService } from "../../services/users.service";
import { Select2Data } from "ng-select2-component";
import { CrewsService } from "../../services/crews.service";

@Component({
  selector: "dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.css"],
})
export class DropdownComponent implements OnInit {
  source: Select2Data = [];
  initial: string;
  title: string;

  @Input() initialValue: string;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() multiple: boolean;
  @Input() all: boolean;
  @Output() selectEvent = new EventEmitter<{}>();

  constructor(
    private usersService: UsersService,
    private crewsService: CrewsService
  ) {}

  ngOnInit(): void {
    console.log("init dropdown")
    console.log(this.type)

    switch (this.type) {
      case "crew-leaders":
        this.title = "Crew leader";
        this.setDataUsers(this.type);

        break;
      case "service-men":
        if (this.multiple) {
          this.title = "Service men";
        } else {
          this.title = "Service man";
        }
        this.setDataUsers(this.type);
        break;
      case "crews":
        this.title = "Crews";
        this.setDataCrews();
        break;
      default:
        this.title = this.type.charAt(0).toUpperCase() + this.type.slice(1, -1);
        this.setDataUsers(this.type);
        break;
    }
  }

  setDefault() {
    this.source = [];
    if (this.all) {
      this.initial = "";
    } else {
      this.initial = this.initialValue;
    }
  }

  setDataCrews() {
    this.crewsService.getCrews().subscribe(
      (response: any) => {
        this.setDefault();
        response.data.map((element) => {
          let item = {
            value: element.id,
            label: element.name,
          };
          this.source.push(item);
        });
      },
      (error) => {}
    );
  }

  setDataUsers(user: string) {
    this.usersService.getUsers(user, undefined).subscribe(
      (response: any) => {
        this.setDefault();
        response.data.map((element) => {
          let item: { value: string; label: string };
          if (this.type === "service-men") {
            item = {
              value: element.id,
              label: element.fullName,
            };
          } else {
            item = {
              value: element.id,
              label: element.name + " " + element.lastName,
            };
          }

          this.source.push(item);
        });

        if (this.all) {
          this.source.unshift({ value: "", label: "All" });
        }
      },
      (error) => {}
    );
  }

  update($event) {
    this.selectEvent.emit($event);
  }
}
