import { Component, Input, OnInit } from "@angular/core";
import { formatDate } from "../../utils/formatDate";

@Component({
  selector: "inspection-history-modal",
  templateUrl: "./inspection-history-modal.component.html",
  styleUrls: ["./inspection-history-modal.component.css"],
})
export class InspectionHistoryModalComponent implements OnInit {
  isError: boolean = false;
  messageError: string = "";
  isWorking: boolean = false;
  isExist: boolean;

  @Input() inspections: any;

  constructor() {}

  ngOnInit(): void {
    this.setData();
  }

  setData() {
    this.inspections.reverse();
    this.inspections.map((inspection, i) => {
      this.isExist = true;
      this.isWorking = true;
      inspection.updatedAt = formatDate(inspection.updatedAt);
      inspection.types.map((element) => {
        if (element.serviceIsWorking === undefined) {
          this.isExist = false;
        }

        if (element.serviceIsWorking === false) {
          this.isWorking = false;
        }
      });

      if (this.isExist === true) {
        if (this.isWorking === true) {
          inspection.isWorking = "Yes";
        } else {
          inspection.isWorking = "No";
        }
      }
    });
  }
}
